import { cementStep } from "./selectData";

/***
 * Variables générales
 ***/
export const homeText = {
  description: "",
  ctaButtons: [
    {
      text: "Equivalence<br>gamme Sika",
      screen: "sika-products",
    },
    {
      text: "Equivalence<br>gamme concurrents",
      screen: "other-products",
    },
  ],
};
export const restart = "Nouvelle simulation";

export const footerEmail = {
  email: "test@mail.mail",
  footerEmailText: "En savoir plus",
};

export const footerLinks = [
  {
    name: "Mentions légales",
    file: "/assets/pdf/mentions-legales.pdf",
  },
  {
    name: "Notice d’utilisation",
    file: "/assets/pdf/notice-mix-flow.pdf",
  },
];

/***
 * Variables d'étapes
 ***/
/**
 * Product-selector
 */
export const productSelectorText = {
  title: "Choix du produit",
  description: "",
  placeholder: "Faites votre choix",
  inputLabel: "Sélectionnez votre produit",
};

export const sikaProductSelectorText = {
  title: "Gamme Sika",
};

export const otherProductSelectorText = {
  title: "Gamme Concurrents",
};

export const cementPortionText = {
  placeholder: "",
  inputLabel: "Equivalence pour x% de ciment + addition",
  small: `Le pourcentage doit être compris entre ${cementStep.min} et ${cementStep.max}`,
  preLabel: "Equivalence pour",
  postLabel: "% de ciment + addition",
};
