import BurgerMenu from "../../atoms/BurgerMenu.js";

export default function Header({ children, className, withBurger }) {
  return (
    <header className={className}>
      {withBurger && <BurgerMenu />}

      {children}
    </header>
  );
}
