/* eslint-disable jsx-a11y/anchor-is-valid */
// npm imports
import React, { Component } from "react";

// internal imports
import Results from "../MixAndFlowResults";
import { calculationData } from "../../data/mixAndFlowData/calculationData";
import {
  sikaProductNames,
  stepNameList,
} from "../../data/mixAndFlowData/selectData";
import {
  resultsPage,
  simulatorResultsText,
} from "../../data/mixAndFlowData/textData";

// local imports
import {
  calculationFunction,
  getCurrentCalculationId,
  semiCrushedCalculationFunction,
} from "./calculationFunctions";

// local imports

class MixAndFlowSimulatorResults extends Component {
  handleNewSimulation = (event) => {
    const { handleResultValidation } = this.props;

    handleResultValidation(event.currentTarget.dataset.screen, []);
  };

  render() {
    const { changeCurrentScreen, userChoices } = this.props;
    const calculId = getCurrentCalculationId(userChoices);
    const currentCalculationData = calculationData.find(
      (calculation) => calculation.calculID === calculId
    );
    let dosageResults = {};

    if (!!currentCalculationData) {
      const userChoiceAggregates = userChoices.find(
        (choice) => choice.stepName === "aggregates"
      ).value;

      if (userChoiceAggregates === "Semi-concassé") {
        dosageResults = semiCrushedCalculationFunction(userChoices);
      } else {
        dosageResults = calculationFunction(userChoices);
      }
    }

    return (
      <section className="simulator-results">
        <h2 className="simulator__title">{resultsPage.title}</h2>

        <div className="results">
          <section className="results__dosage">
            <Results
              dosage={dosageResults}
              isSimulator
              productNames={sikaProductNames}
            />

            <div className="results__issues">
              {resultsPage.issueText}
              <br />
              {resultsPage.issueText2}
              <br />
              {resultsPage.issueText3}{" "}
              <span>
                <a
                  href={
                    "https://docs.google.com/gview?url=https://" +
                    window.location.host +
                    "/assets/pdf/Fiche_Mix_Flow_2_-_resultats.pdf&embedded=true"
                  }
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {resultsPage.linkText}
                </a>
              </span>
            </div>
          </section>

          <hr />

          <section className="results__user-choices">
            <h4 className="user-choices__title">
              {simulatorResultsText.title}
            </h4>

            <button
              className="btn"
              onClick={changeCurrentScreen}
              data-screen="simulator"
            >
              {simulatorResultsText.button}
            </button>

            {userChoices.map((choice) => {
              const choiceTitle = stepNameList.find(
                (stepName) => stepName.name === choice.stepName
              ).text;

              return (
                <p key={choice.stepName} className="user-choices__item">
                  {choiceTitle} : <strong>{choice.value}</strong>
                </p>
              );
            })}
          </section>
        </div>
      </section>
    );
  }
}

export default MixAndFlowSimulatorResults;
