import React, { Component } from "react";
import { Form } from "react-bootstrap";

import SelectInput from "../SelectInput";
import { carbonFootprintData } from "../../data/co2AppData/carbonFootprintData";
import { productDataBlockTexts } from "../../data/co2AppData/textData";
import { sortArrayByName } from "../../utils/arrayUtils";

class ProductDataBlock extends Component {
  // ! @todo : reprendre le css pour le select
  state = {
    hasProducts: false,
    quantityHasError: false,
    userChoices: {},
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    const nextProductData = nextProps.productData;
    const prevUserChoices = prevState.userChoices;

    let userChoices = prevUserChoices;

    if (nextProductData !== prevUserChoices) {
      userChoices = nextProductData;
    }

    return {
      userChoices,
    };
  }

  getProductFamilySelectData = () => {
    return carbonFootprintData.map((data) => {
      return {
        name: data.productFamily,
      };
    });
  };

  getSelectText = (title) => {
    return productDataBlockTexts.find((type) => type.title === title);
  };

  getUserChoice = (key) => {
    const { userChoices } = this.state;

    return userChoices[key] || "";
  };

  getCurrentProductFamilyData = (productFamily) => {
    return carbonFootprintData.find(
      (data) => data.productFamily === productFamily
    );
  };

  getProductNameSelectData = () => {
    const {
      hasProducts,
      userChoices: { productFamily },
    } = this.state;

    if (!hasProducts) {
      return;
    }

    const currentProductFamilyData =
      this.getCurrentProductFamilyData(productFamily);

    if (!!currentProductFamilyData) {
      const currentProductList = currentProductFamilyData.products.map(
        (productData) => {
          return {
            name: productData.productName,
          };
        }
      );

      return currentProductList;
    }
  };

  updateFormulation = (event) => {
    const { changeFormulationState, formulation } = this.props;
    const { userChoices } = this.state;
    const name = event.currentTarget.name;
    const value = event.currentTarget.value;
    let newProductData = { ...userChoices };

    if (name === "productFamily") {
      const productFamily = value;
      const currentProductFamily =
        this.getCurrentProductFamilyData(productFamily);

      /**
       * on update les données de la famille de produit
       */
      const productFamilyCarbonFootprint =
        currentProductFamily.productFamilyCarbonFootprint;
      const subProductFamily = currentProductFamily.subProductFamily;
      const hasProducts = !!currentProductFamily.products;
      /**
       * on reset les données du produit
       */
      const productName = null;
      const productCarbonFootprint = null;
      const subProducts = null;

      newProductData = {
        ...userChoices,
        productFamily,
        productFamilyCarbonFootprint,
        subProductFamily,
        hasProducts,
        productName,
        productCarbonFootprint,
        subProducts,
      };

      this.setState({ hasProducts });
    } else if (name === "productName") {
      const productName = value;
      const currentProductFamily = this.getCurrentProductFamilyData(
        userChoices.productFamily
      );

      if (!!currentProductFamily) {
        const currentProductList = currentProductFamily.products;

        if (!!currentProductList.length) {
          const currentProductData = currentProductList.find(
            (product) => product.productName === value
          );

          if (!!currentProductData) {
            const productCarbonFootprint =
              currentProductData.productCarbonFootprint;
            const subProducts = currentProductData.subProducts;

            newProductData = {
              ...userChoices,
              productName,
              productCarbonFootprint,
              subProducts,
            };
          }
        }
      }
    } else if (name === "quantity") {
      const quantity = value;

      newProductData = {
        ...userChoices,
        quantity,
      };

      this.checkQuantity(quantity);
    }

    let newFromulation = formulation.map((product) => {
      if (product.choiceId === userChoices.choiceId) {
        return newProductData;
      }

      return product;
    });

    changeFormulationState(newFromulation);
  };

  checkQuantity = (quantity) => {
    if (!!quantity) {
      this.setState({ quantityHasError: false });
    } else {
      this.setState({ quantityHasError: true });
    }
  };

  removeBlock = () => {
    const { changeFormulationState, index, formulation } = this.props;
    const newFromulation = Array.from(formulation);
    newFromulation.splice(index, 1);

    changeFormulationState(newFromulation);
  };

  render() {
    const { hasProducts, quantityHasError, userChoices } = this.state;
    const productFamilySelectData = this.getProductFamilySelectData();
    const productFamilySelectText = this.getSelectText("Famille");
    const selectedProductFamily = this.getUserChoice("productFamily");
    const productNameSelectData = this.getProductNameSelectData();
    const productNameSelectText = this.getSelectText("Produit");
    const selectedProductName = this.getUserChoice("productName");
    const quantityInputText = this.getSelectText("Quantité");
    const quantity = this.getUserChoice("quantity").toString();

    return (
      <div className="product-data-block">
        <SelectInput
          data={sortArrayByName(productFamilySelectData)}
          name="productFamily"
          onChange={this.updateFormulation}
          selectedOption={selectedProductFamily}
          text={productFamilySelectText}
          withDelete
          onDelete={this.removeBlock}
        />

        {userChoices.productFamily && hasProducts && (
          <SelectInput
            data={sortArrayByName(productNameSelectData)}
            name="productName"
            onChange={this.updateFormulation}
            selectedOption={selectedProductName}
            text={productNameSelectText}
          />
        )}

        <div>
          <Form.Group className="form-group">
            <Form.Label>{quantityInputText.inputLabel}</Form.Label>
            <Form.Control
              size="lg"
              type="text"
              name="quantity"
              placeholder={quantityInputText.placeholder}
              isInvalid={quantityHasError}
              value={quantity}
              onChange={this.updateFormulation}
              className={
                quantityHasError
                  ? "hasError"
                  : !quantityHasError
                  ? "isFilled"
                  : ""
              }
            />
            <Form.Control.Feedback type="invalid">
              {quantityInputText.small}
            </Form.Control.Feedback>
          </Form.Group>
        </div>
      </div>
    );
  }
}

export default ProductDataBlock;
