import SikaHeader from "../SikaHeader";
import { legaleNoticeData } from "../../data/legalNoticeData";

export default function LegalNotice() {
  return (
    <>
      <SikaHeader withBurger />

      <main id="legal-notice">
        <h1 className="">{legaleNoticeData.title}</h1>

        <p>{legaleNoticeData.content}</p>
      </main>
    </>
  );
}
