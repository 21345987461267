// npm imports
import React, { useState } from "react";
import { Form } from "react-bootstrap";

// internalImports
import Results from "../MixAndFlowResults";
import SelectInput from "../SelectInput";
import { sortArrayByName } from "../../utils/arrayUtils";

function EquivalentProductSelector({
  cementPortionText,
  cementStep,
  data,
  isWhite = false,
  productNames,
  productSelectorText,
  texts,
}) {
  const [cementPortionError, setCementPortionError] = useState(false);
  const [currentProduct, setCurrentProduct] = useState("");
  const [currentProductDoses, setCurrentProductDoses] = useState({});
  const [
    currentProductAlternativeChoices,
    setCurrentProductAlternativeChoices,
  ] = useState({});
  const [currentCementPortion, setCurrentCementPortion] = useState(1);

  const handleOnSubmit = (event) => {
    event.preventDefault();
  };

  const currentUserChoice = data.find(
    (product) => product.name === currentProduct
  );

  const setUserChoice = (event) => {
    const product = event.currentTarget.value;

    setCurrentProduct(product);
    const currentProductData = data.find(
      (sikaProduct) => sikaProduct.name === product
    );

    if (!!currentProductData && !!currentProductData.alternativeChoices) {
      setCurrentProductAlternativeChoices(
        currentProductData.alternativeChoices
      );
    }

    setUserCurrentParamsDoses(product);
  };

  const setUserCurrentParamsDoses = (userProduct) => {
    let currentProductDoses = userProduct
      ? data.find((product) => product.name === userProduct).doses
      : {};

    /**
     * si l'équivalence pour x% de ciment est différent de 1
     */
    if (!!currentProductDoses) {
      setCurrentProductDoses(currentProductDoses);
    }
  };

  const setUserCurrentCementPortion = (event) => {
    const userCurrentCementPortion = event.currentTarget.value;
    let cementPortionError = false;

    if (
      userCurrentCementPortion < cementStep.min ||
      userCurrentCementPortion > cementStep.max
    ) {
      cementPortionError = true;
    }

    setCurrentCementPortion(userCurrentCementPortion);
    setCementPortionError(cementPortionError);
    setUserCurrentParamsDoses(currentProduct);
  };

  return (
    <section className="product-selector">
      <h2 className="simulator__title">{texts.title}</h2>

      <div className="product-selector__content">
        <Form onSubmit={handleOnSubmit} className="product-selector__form">
          <SelectInput
            key="sika-product"
            data={sortArrayByName(data)}
            name="sika-product"
            selectedOption={currentUserChoice?.name ?? ""}
            onChange={setUserChoice}
            text={productSelectorText}
          />

          <Form.Group className="form-group cement-portion">
            <Form.Label>{cementPortionText.preLabel}</Form.Label>
            <Form.Control
              size="lg"
              type="text"
              name="cement-portion"
              placeholder={cementPortionText.placeholder}
              value={currentCementPortion}
              onChange={setUserCurrentCementPortion}
              isInvalid={cementPortionError}
            />
            <Form.Label>{cementPortionText.postLabel}</Form.Label>
            <Form.Control.Feedback type="invalid">
              {cementPortionText.small}
            </Form.Control.Feedback>
          </Form.Group>
        </Form>

        {!!currentProduct && !!currentProductDoses && !cementPortionError && (
          <>
            <hr />
            <section className={`results__dosage ${ isWhite ? ' is-white' : ''}`}>
              <Results
                dosage={currentProductDoses}
                alternativeChoices={currentProductAlternativeChoices}
                currentCementPortion={currentCementPortion}
                productNames={productNames}
              />
            </section>
          </>
        )}
      </div>
    </section>
  );
}

export default EquivalentProductSelector;
