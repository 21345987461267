/* eslint-disable jsx-a11y/anchor-is-valid */
// npm imports
import React from "react";

// internal import

function Footer({ footerLinks }) {
  if (!footerLinks) {
    return null;
  }

  return (
    <footer className="footer">
      <ul className="footer__links">
        {footerLinks.map((link) => (
          <li key={link.name} className="footer__links__item">
            <a
              target="_blank"
              rel="noopener noreferrer"
              href={
                "https://docs.google.com/gview?url=https://" +
                window.location.host +
                link.file +
                "&embedded=true"
              }
            >
              {link.name}
            </a>
          </li>
        ))}
      </ul>
    </footer>
  );
}

export default Footer;
