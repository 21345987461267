import React, { Component } from "react";
import { Form } from "react-bootstrap";

import AddProductButton from "./AddProductButton";
import CarbonFootprintIntroBlock from "../CarbonFootprintIntroBlock";
import ProductDataBlock from "./ProductDataBlock";
import {
  comparativeReportFirstFormulationScreenTexts,
  comparativeReportSecondFormulationScreenTexts,
  diagnosticTestFormulationScreenTexts,
  screen,
} from "../../data/co2AppData/textData";

class CarbonFootprintFormulationScreen extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isValid: this.checkFormValidity(this.props.formulation),
    };
  }

  componentDidUpdate(prevProps, prevState) {
    const { formulation } = this.props;
    const { formulation: prevFormulation } = prevProps;

    if (formulation !== prevFormulation) {
      const isValid = this.checkFormValidity(formulation);

      this.setState({ isValid });
    }
  }

  checkFormValidity = (formulation = []) =>
    formulation.length > 0 && formulation.every(this.checkProductData);

  checkProductData = (productData) => {
    let isValid = true;

    if (
      !productData.quantity ||
      (productData.hasProducts && !productData.productName)
    ) {
      isValid = false;
    }

    return isValid;
  };

  handleFormSubmit = (event) => {
    event.preventDefault();

    const { changeCurrentScreen } = this.props;

    changeCurrentScreen(event);
  };

  render() {
    const {
      canAddAProduct,
      changeFormulationState,
      currentScreen,
      formulation,
      updateFormulationWithNewProduct,
    } = this.props;
    const { isValid } = this.state;

    let texts = {};
    let ctaText = "";
    let dataScreen = "";

    switch (currentScreen) {
      case screen.comparativeReportFormulation1:
        texts = comparativeReportFirstFormulationScreenTexts;
        ctaText = texts.cta;
        dataScreen = texts.nextScreen;
        break;
      case screen.comparativeReportFormulation2:
        texts = comparativeReportSecondFormulationScreenTexts;
        ctaText = texts.cta;
        dataScreen = texts.nextScreen;
        break;
      case screen.diagnosticTest:
        texts = diagnosticTestFormulationScreenTexts;
        ctaText = texts.cta;
        dataScreen = texts.nextScreen;
        break;
      default:
        break;
    }

    ctaText =
      ctaText && !formulation.length
        ? texts.cta.find((cta) => cta.action === "isEmpty")
        : isValid
        ? texts.cta.find((cta) => cta.action === "isValid")
        : ctaText && !isValid
        ? texts.cta.find((cta) => cta.action === "isNotValid")
        : "";

    return (
      <section className="formulation-screen">
        {!!texts && <CarbonFootprintIntroBlock texts={texts} />}

        <Form
          className="simulator__form complete-form"
          data-screen={dataScreen}
          onSubmit={this.handleFormSubmit}
          method="post"
        >
          {formulation &&
            !!formulation.length &&
            formulation.map((productData, index) => {
              return (
                <ProductDataBlock
                  index={index}
                  key={`${productData.choiceId}-product-data-block`}
                  changeFormulationState={changeFormulationState}
                  formulation={formulation}
                  productData={productData}
                />
              );
            })}

          <AddProductButton
            canAddAProduct={canAddAProduct}
            updateFormulationWithNewProduct={updateFormulationWithNewProduct}
          />

          {!!ctaText && (
            <button
              className="btn simulator__form__validate"
              data-screen={dataScreen}
              disabled={!isValid}
            >
              {ctaText.text}
            </button>
          )}
        </Form>
      </section>
    );
  }
}

export default CarbonFootprintFormulationScreen;
