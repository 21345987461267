//* Constants
export const screen = {
  comparativeReportFormulation1: "comparative-report-formulation-1",
  comparativeReportFormulation2: "comparative-report-formulation-2",
  comparativeReportResult: "comparative-report-result",
  diagnosticTest: "diagnostic-test",
  diagnosticTestResult: "diagnostic-test-result",
  home: "home",
};

const title = {
  diagnosticTest: "Bilan pour une formule",
  comparativeReport: "Comparatif pour deux formules",
};

//* Pages
// home
export const homeText = {
  title: "Bilan carbon",
  description: "",
  ctaButtons: [
    {
      text: title.diagnosticTest,
      screen: screen.diagnosticTest,
    },
    {
      text: title.comparativeReport,
      screen: screen.comparativeReportFormulation1,
    },
  ],
};

// Diagnostic Test - Formulation
export const diagnosticTestFormulationScreenTexts = {
  title: title.diagnosticTest,
  description: "",
  cta: [
    {
      action: "isValid",
      text: "Valider",
    },
    {
      action: "isNotValid",
      text: "Merci de compléter les champs",
    },
    {
      action: "isEmpty",
      text: "Merci d'ajouter au moins un constituant",
    },
  ],
  nextScreen: screen.diagnosticTestResult,
};

// Diagnostic Test - Results
export const diagnosticTestResultTexts = {
  title: title.diagnosticTest,
  description: "Bilan carbon d'une formulation spécifique",
  firstResultLabel: "Bilan CO2 Formule 1",
  cta: [
    {
      text: "Comparer cette formule",
      screen: screen.comparativeReportFormulation2,
      classNames: "",
    },
    {
      text: "Retour",
      screen: screen.home,
      classNames: "btn--secondary",
    },
  ],
};

// Comparative report - Formulation 1
export const comparativeReportFirstFormulationScreenTexts = {
  title: title.comparativeReport,
  description:
    "Comparaison d'évaluation carbone entre 2 formules - Saisie des constituants de la formule 1",
  cta: [
    {
      action: "isValid",
      text: "Saisir Formule 2",
    },
    {
      action: "isNotValid",
      text: "Merci de compléter les champs",
    },
    {
      action: "isEmpty",
      text: "Merci d'ajouter au moins un constituant",
    },
  ],
  nextScreen: screen.comparativeReportFormulation2,
};

// Comparative report - Formulation 2
export const comparativeReportSecondFormulationScreenTexts = {
  title: title.comparativeReport,
  description:
    "Comparaison d'évaluation carbone entre 2 formules - Saisie des constituants de la formule 2",
  cta: [
    {
      action: "isValid",
      text: "Comparer",
    },
    {
      action: "isNotValid",
      text: "Merci de compléter les champs",
    },
    {
      action: "isEmpty",
      text: "Merci d'ajouter au moins un constituant",
    },
  ],
  nextScreen: screen.comparativeReportResult,
};

// Comparative report - Results
export const comparativeReportResultTexts = {
  title: title.comparativeReport,
  description: "Comparatif du Bilan carbon de 2 formules spécifiques",
  firstResultLabel: "Bilan CO2 Formule 1",
  secondResultLabel: "Bilan CO2 Formule 2",
  carbonGainLabel:
    "Pourcentage d'augmentation de CO2 de la formule 2 par rapport à la formule 1",
  carbonReductionLabel:
    "Pourcentage de réduction de CO2 de la formule 2 par rapport à la formule 1",
  cta: [
    {
      text: "Nouvelle comparaison",
      screen: screen.comparativeReportFormulation2,
      classNames: "",
    },
    {
      text: "Retour",
      screen: screen.home,
      classNames: "btn--secondary",
    },
  ],
};

//* Blocks
export const productDataBlockTexts = [
  {
    title: "Famille",
    description: "",
    placeholder: "Faites votre choix",
    inputLabel: "Famille",
  },
  {
    title: "Produit",
    description: "",
    placeholder: "Faites votre choix",
    inputLabel: "Produit",
  },
  {
    title: "Quantité",
    description: "",
    placeholder: "Entrez la quantité",
    inputLabel: "Quantité en kg/m3",
    small: "Merci de saisir une quantité minimale à 0",
  },
];

export const addProductButtonText = {
  enabled: "Ajouter un constituant",
  disabled: "Veuillez compléter les champs du nouveau produit",
};
