import { alcalinStep, cementStep } from "./selectData";

/***
 * Variables générales
 ***/

export const installModalText = {
  appleInstallText:
    "Pour installer cette application,<br>veuillez suivre les étapes ci-dessous",
  appleCloseButton: "Fermez",
  installButton: "Vous pouvez installer l'application",
};

export const uninstallModalText = {
  unsinstallText:
    "Votre session a expiré. Assurez-vous d'avoir une connexion internet pour vous authentifier.",
  uninstallButtonOffLine: "M'authentifier",
  uninstallButtonOffLineTwice: "Pas de connexion detectée.",
};
export const restart = "Nouvelle simulation";

export const footerEmail = {
  email: "test@mail.mail",
  footerEmailText: "En savoir plus",
};

export const footerLinks = [
  {
    name: "Mentions légales",
    file: "/assets/pdf/mentions-legales.pdf",
  },
  {
    name: "Notice d’utilisation",
    file: "/assets/pdf/notice-mix-flow.pdf",
  },
];

/***
 * Variables d'étapes
 ***/
/**
 * Product-selector
 */
export const productSelectorText = {
  title: "Choix du produit",
  description: "",
  placeholder: "Faites votre choix",
  inputLabel: "Sélectionnez votre produit",
};

export const sikaProductSelectorText = {
  title: "Gamme Sika",
};

export const cementPortionText = {
  placeholder: "",
  inputLabel: "Equivalence pour x% de ciment + addition",
  small: `Le pourcentage doit être compris entre ${cementStep.min} et ${cementStep.max}`,
  preLabel: "Equivalence pour",
  postLabel: "% de ciment + addition",
};

/**
 * simulator
 */
export const simulatorTitle = "Simulateur sur mesure";

export const exposureClassesText = {
  title: "Classe d'exposition",
  description: "",
  placeholder: "Faites votre choix",
  inputLabel: "Sélectionnez la classe d'exposition",
};

export const concreteStepText = {
  title: "Type de ciment",
  description: "",
  placeholder: "Faites votre choix",
  inputLabel: "Sélectionnez le type de ciment",
};

export const alcalinStepText = {
  title: "Taux d'alcalin",
  description: "",
  placeholder: "",
  inputLabel: "Saisissez le taux d'alcalin souhaité",
  small: `Il doit être compris entre ${alcalinStep.min} et ${alcalinStep.max}`,
};

export const aggregatesStepText = {
  title: "Type de granulats",
  description: "",
  placeholder: "Faites votre choix",
  inputLabel: "Sélectionnez le type de granulats",
};

export const consistencyStepText = {
  title: "Consistance visée",
  description: "",
  placeholder: "Faites votre choix",
  inputLabel: "Sélectionnez la consistance visée",
};

export const temperatureStepText = {
  title: "Température",
  description: "",
  placeholder: "Faites votre choix",
  inputLabel: "Sélectionnez la température",
};

export const formSubmitButtonText = {
  isValid: "Voir le résultat de la simulation",
  isNotValid: "Merci de compléter les champs",
};

export const simulatorResultsText = {
  title: "Rappel de vos choix",
  button: "Modifiez vos choix",
};
export const resultsPage = {
  title: "Résultats",
  dosageTitle:
    "Choix principal<br>Dosages indicatifs<br><span>(% Ciment + Addition)</span>",
  dosageTotal: "Total Mix & Flow",
  issueText: "Besoin d’affiner vos dosages suite à vos essais ?",
  issueText2: "Veuillez trouver une documentation",
  issueText3: "qui pourra vous aider",
  linkText: "ICI",
  equivalentTitle: "Choix alternatifs",
  equivalentTitleSingular: "Choix alternatif",
};
