import React from "react";

import CarbonFootprintIntroBlock from "../../components/CarbonFootprintIntroBlock";
import SingleFormulationResult from "./SingleFormulationResult";
import CarbonGainBlock from "./CarbonGainBlock";

import {
  comparativeReportResultTexts,
  diagnosticTestResultTexts,
  screen,
} from "../../data/co2AppData/textData";
import { getFormulationCarbonFootprint } from "../../utils/carbonFootPrint";

const CarbonFootprintResult = ({
  changeCurrentScreen,
  currentScreen,
  initialFormulation,
  resetState,
  secondFormulation,
}) => {
  let texts = {};

  switch (currentScreen) {
    case screen.diagnosticTestResult:
      texts = diagnosticTestResultTexts;
      break;
    case screen.comparativeReportResult:
      texts = comparativeReportResultTexts;
      break;
    default:
      break;
  }

  const ctaList = (!!texts && !!texts.cta?.length && texts.cta) || [];

  const initialFormulationResult =
    !!initialFormulation.length &&
    getFormulationCarbonFootprint(initialFormulation);

  const secondFormulationResult =
    currentScreen === screen.comparativeReportResult &&
    !!secondFormulation.length &&
    getFormulationCarbonFootprint(secondFormulation);

  const carbonGainPercentage =
    currentScreen === screen.comparativeReportResult &&
    initialFormulationResult > 0 &&
    secondFormulationResult > 0
      ? ((initialFormulationResult - secondFormulationResult) /
          initialFormulationResult) *
        100
      : 0;

  const handleButtonClick = (event) => {
    event.preventDefault();

    const nextScreen = event.currentTarget.dataset.screen;

    // empty state on return to home only
    if (nextScreen === screen.home) {
      resetState(); // redirect to home as well
    } else {
      // redirection
      changeCurrentScreen(event);
    }
  };

  return (
    <section className="result-screen">
      <CarbonFootprintIntroBlock texts={texts} />

      <article className="result__item">
        <SingleFormulationResult
          comparative={currentScreen === screen.comparativeReportResult}
          result={initialFormulationResult}
          title={texts.firstResultLabel}
        />
      </article>

      {currentScreen === screen.comparativeReportResult && (
        <>
          <article className="result__item">
            <SingleFormulationResult
              comparative
              result={secondFormulationResult}
              title={texts.secondResultLabel}
            />
          </article>

          <article>
            <CarbonGainBlock
              result={carbonGainPercentage}
              title={
                carbonGainPercentage < 0
                  ? texts.carbonGainLabel
                  : texts.carbonReductionLabel
              }
            />
          </article>
        </>
      )}

      {!!ctaList.length && (
        <article className="button-block">
          {ctaList.map((cta) => (
            <button
              key={cta.text}
              className={`btn btn-lg ${cta.classNames}`}
              data-screen={cta.screen}
              onClick={handleButtonClick}
            >
              {cta.text}
            </button>
          ))}
        </article>
      )}
    </section>
  );
};

export default CarbonFootprintResult;
