import React from "react";

const CarbonFootprintIntroBlock = ({ texts }) => {
  return (
    <div className="carbon-footprint__intro">
      <h2 className="formulation-screen__title">{texts.title}</h2>
      {!!texts.description && (
        <p className="formulation-screen__description">{texts.description}</p>
      )}
    </div>
  );
};

export default CarbonFootprintIntroBlock;
