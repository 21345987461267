// npm imports
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

// internal imports
import { authorizedUsers } from "../../data/userData";
import SikaHeader from "../SikaHeader";

function Authentification({ authorizeCurrentUser }) {
  const history = useNavigate();
  const [login, setLogin] = useState("");
  const [pass, setPass] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  function handleChange(event) {
    const target = event.currentTarget.name;
    const value = event.currentTarget.value;

    if (target === "login") {
      setLogin(value);
    } else if (target === "pass") {
      setPass(value);
    }
  }

  function handleBlur(event) {
    const currentTarget = event.currentTarget;

    if (!!currentTarget.value) {
      currentTarget.classList.add("isFilled");
    } else {
      currentTarget.classList.remove("isFilled");
    }
  }

  function handleSubmit(event) {
    event.preventDefault();

    // test si le login est valide
    const validUser = authorizedUsers.find((user) => user.login === login);

    // test si le mot de passe est valide
    const isValidPassword = !!validUser && validUser.password === pass;

    if (!validUser || !isValidPassword) {
      setErrorMessage("Identifiant et / ou mot de passe incorrect(s)");
    } else {
      authorizeCurrentUser();

      history("/");
    }
  }

  return (
    <>
      <SikaHeader />

      <form className="authentification__form" onSubmit={handleSubmit}>
        {errorMessage && <div className="error-message">{errorMessage}</div>}

        <div className="authentification__form__item">
          <label htmlFor="login">Identifiant</label>
          <input
            type="text"
            name="login"
            id="login"
            placeholder="Identifiant"
            onChange={handleChange}
            value={login}
            onBlur={handleBlur}
            className={!!login ? "isFilled" : ""}
          />
        </div>
        <div className="authentification__form__item">
          <label htmlFor="pass">Mot de passe</label>
          <input
            type="password"
            name="pass"
            id="pass"
            placeholder="Mot de passe"
            onChange={handleChange}
            value={pass}
            onBlur={handleBlur}
            className={!!pass ? "isFilled" : ""}
          />
        </div>

        <input className="btn btn-lg" type="submit" value="Valider" id="validation" />
      </form>
    </>
  );
}

export default Authentification;
