export const otherProductSelectorText = {
  title: "Gamme Concurrents",
};

export const otherProducts = [
  {
    name: "Chrysoplast Delta 20",
    doses: {
      power: 30 / 100,
      soft: 0 / 100,
      timer: 20 / 100,
    },
  },
  {
    name: "Chryso Delta 515 EMX",
    doses: {
      power: 10 / 100,
      soft: 45 / 100,
      timer: 15 / 100,
    },
  },
  {
    name: "Chrysoplast Omega 132",
    doses: {
      power: 10 / 100,
      soft: 45 / 100,
      timer: 15 / 100,
    },
  },
  {
    name: "Chrysoplast Omega 135",
    doses: {
      power: 57 / 100,
      soft: 0 / 100,
      timer: 15 / 100,
    },
  },
  {
    name: "Chrysoplast Omega 137 EMX",
    doses: {
      power: 57 / 100,
      soft: 0 / 100,
      timer: 15 / 100,
    },
  },
  {
    name: "Chrysoplast Omega 152",
    doses: {
      power: 45 / 100,
      soft: 20 / 100,
      timer: 10 / 100,
    },
  },
  {
    name: "Chryso Omega 418",
    doses: {
      power: 12 / 100,
      soft: 52 / 100,
      timer: 15 / 100,
    },
  },
  {
    name: "Chryso Quad 630",
    doses: {
      power: 30 / 100,
      soft: 50 / 100,
      timer: 10 / 100,
    },
  },
  {
    name: "Chrysofluid Optima 224",
    doses: {
      power: 35 / 100,
      soft: 35 / 100,
      timer: 0 / 100,
    },
  },
  {
    name: "Chrysofluid Optima 350",
    doses: {
      power: 80 / 100,
      soft: 30 / 100,
      timer: 0 / 100,
    },
  },
  {
    name: "Chrysofluid Optima 372",
    doses: {
      power: 50 / 100,
      soft: 35 / 100,
      timer: 0 / 100,
    },
  },
  {
    name: "MasterPolyheed 510",
    doses: {
      power: 10 / 100,
      soft: 45 / 100,
      timer: 15 / 100,
    },
  },
  {
    name: "MasterPolyheed 520",
    doses: {
      power: 57 / 100,
      soft: 0 / 100,
      timer: 15 / 100,
    },
  },
  {
    name: "MasterPolyheed 650",
    doses: {
      power: 57 / 100,
      soft: 0 / 100,
      timer: 15 / 100,
    },
  },
  {
    name: "MasterPolyheed 705",
    doses: {
      power: 45 / 100,
      soft: 20 / 100,
      timer: 10 / 100,
    },
  },
  {
    name: "MasterPolyheed 787",
    doses: {
      power: 30 / 100,
      soft: 50 / 100,
      timer: 10 / 100,
    },
  },
];
