export const calculationData = [
	{
		calculID: 1,
		power: {
			constante: -0.2132,
			s4Consistency: 0.084,
			bapConsistency: 0.251,
			aggregates: 0.1018,
			alcalin: 0.63,
		},
		soft: {
			constante: 0.2262,
			s4Consistency: 0.01067,
			bapConsistency: 0.244,
			aggregates: 0.07822,
			alcalin: -0.21,
		},
		timer: {
			constante: 0.1,
			s4Consistency: -0.05,
			bapConsistency: -0.1,
			aggregates: 0,
			alcalin: 0,
		},
	},
	{
		calculID: 2,
		power: {
			constante: -0.2106,
			s4Consistency: 0.06667,
			bapConsistency: 0.2617,
			aggregates: 0.09111,
			alcalin: 0.6167,
		},
		soft: {
			constante: 0.2578,
			s4Consistency: 0.01333,
			bapConsistency: 0.2133,
			aggregates: 0.09778,
			alcalin: -0.2278,
		},
		timer: {
			constante: 0.1,
			s4Consistency: -0.05,
			bapConsistency: -0.1,
			aggregates: 0,
			alcalin: 0,
		},
	},
	{
		calculID: 3,
		power: {
			constante: -0.1778,
			s4Consistency: 0.08333,
			bapConsistency: 0.225,
			aggregates: 0.1056,
			alcalin: 0.625,
		},
		soft: {
			constante: 0.2528,
			s4Consistency: 0.01667,
			bapConsistency: 0.275,
			aggregates: 0.1111,
			alcalin: -0.1389,
		},
		timer: {
			constante: 0,
			s4Consistency: 0,
			bapConsistency: 0,
			aggregates: 0,
			alcalin: 0,
		},
	},
	{
		calculID: 4,
		power: {
			constante: -0.1311,
			s4Consistency: 0.085,
			bapConsistency: 0.1967,
			aggregates: 0.1089,
			alcalin: 0.6306,
		},
		soft: {
			constante: 0.3178,
			s4Consistency: 0.01833,
			bapConsistency: 0.2483,
			aggregates: 0.1144,
			alcalin: -0.1611,
		},
		timer: {
			constante: 0,
			s4Consistency: 0,
			bapConsistency: 0,
			aggregates: 0,
			alcalin: 0,
		},
	},
	{
		calculID: 5,
		power: {
			constante: -0.08756,
			s4Consistency: 0.08833,
			bapConsistency: 0.135,
			aggregates: 0.1111,
			alcalin: 0.6556,
		},
		soft: {
			constante: 0.3872,
			s4Consistency: 0.055,
			bapConsistency: 0.1917,
			aggregates: 0.07222,
			alcalin: -0.1472,
		},
		timer: {
			constante: 0,
			s4Consistency: 0,
			bapConsistency: 0,
			aggregates: 0,
			alcalin: 0,
		},
	},
	{
		calculID: 6,
		power: {
			constante: -0.2194,
			s4Consistency: 0.06,
			bapConsistency: 0.255,
			aggregates: 0.09556,
			alcalin: 0.5833,
		},
		soft: {
			constante: 0.2439,
			s4Consistency: 0.01333,
			bapConsistency: 0.1983,
			aggregates: 0.08889,
			alcalin: -0.2,
		},
		timer: {
			constante: 0.1,
			s4Consistency: -0.05,
			bapConsistency: -0.1,
			aggregates: 0,
			alcalin: 0,
		},
	},
	{
		calculID: 7,
		power: {
			constante: -0.07556,
			s4Consistency: 0.07333,
			bapConsistency: 0.1133,
			aggregates: 0.0944,
			alcalin: 0.5611,
		},
		soft: {
			constante: 0.3283,
			s4Consistency: 0.045,
			bapConsistency: 0.1633,
			aggregates: 0.06,
			alcalin: -0.1222,
		},
		timer: {
			constante: 0,
			s4Consistency: 0,
			bapConsistency: 0,
			aggregates: 0,
			alcalin: 0,
		},
	},
]