/**
 * PRODUCTS
 */
export const sikaProductNames = {
  flowcast: "500 Flowcast",
  kronocast: "1000 KronoCast",
  polycast: "700 Polycast",
};

export const sikaProducts = [
  {
    name: "Krono 20 HE",
    doses: {
      kronocast: 105 / 100,
    },
  },
  {
    name: "Krono 26",
    doses: {
      kronocast: 65 / 100,
      flowcast: 20 / 100,
    },
    alternativeChoices: [
      {
        polycast: 130 / 100,
      },
    ],
  },
  {
    name: "Krono 30",
    doses: {
      kronocast: 60 / 100,
      flowcast: 20 / 100,
    },
    alternativeChoices: [
      {
        polycast: 80 / 100,
      },
    ],
  },
  {
    name: "Krono 936",
    doses: {
      kronocast: 60 / 100,
      flowcast: 20 / 100,
    },
    alternativeChoices: [
      {
        polycast: 80 / 100,
      },
    ],
  },
  {
    name: "Krono 37",
    doses: {
      polycast: 95 / 100,
    },
    alternativeChoices: [],
  },
  {
    name: "Krono 46",
    doses: {
      polycast: 120 / 100,
    },
    alternativeChoices: [
      {
        kronocast: 75 / 100,
        flowcast: 30 / 100,
      },
    ],
  },
  {
    name: "Krono 867",
    doses: {
      kronocast: 90 / 100,
    },
    alternativeChoices: [
      {
        polycast: 120 / 100,
      },
    ],
  },
  {
    name: "Krono 947",
    doses: {
      kronocast: 75 / 100,
      flowcast: 20 / 100,
    },
    alternativeChoices: [
      {
        polycast: 100 / 100,
      },
    ],
  },
  {
    name: "Krono 951",
    doses: {
      kronocast: 80 / 100,
    },
    alternativeChoices: [],
  },
  {
    name: "Krono 918",
    doses: {
      polycast: 105 / 100,
    },
    alternativeChoices: [],
  },
  {
    name: "Tempo 10",
    doses: {
      polycast: 100 / 100,
    },
    alternativeChoices: [],
  },
  {
    name: "Tempo 11",
    doses: {
      polycast: 75 / 100,
    },
    alternativeChoices: [],
  },
  {
    name: "Techno 430",
    doses: {
      kronocast: 60 / 100,
      flowcast: 20 / 100,
    },
    alternativeChoices: [
      {
        polycast: 90 / 100,
      },
    ],
  },
];

export const otherProducts = [
  {
    name: "Mastersure HES 1503",
    doses: {
      kronocast: 80 / 100,
      flowcast: 20 / 100,
    },
    alternativeChoices: [
      {
        polycast: 120 / 100,
      },
    ],
  },
  {
    name: "Glenium ACE 550",
    doses: {
      kronocast: 100 / 100,
    },
    alternativeChoices: [],
  },
  {
    name: "Glenium ACE 590",
    doses: {
      kronocast: 90 / 100,
      flowcast: 15 / 100,
    },
    alternativeChoices: [],
  },
  {
    name: "Premia 570",
    doses: {
      kronocast: 85 / 100,
    },
    alternativeChoices: [],
  },
  {
    name: "Premia 595",
    doses: {
      kronocast: 95 / 100,
    },
    alternativeChoices: [],
  },
];

export const cementStep = {
  min: 0,
  max: 2,
};
