// npm imports
import React, { Component } from "react";

// intern imports.
import SikaLogoOnTableAndAbove from "../../assets/img/Sika_ClaimU_pos_rgb.svg";
import SikaLogoOnMobile from "../../assets/img/Sika_NoClaim_pos_rgb_mobile.png";
import { restart } from "../../data/mixAndFlowData/textData";
import Header from "../Header";

// local imports

class MixAndFlowHeader extends Component {
  handleRestart = (event) => {
    this.props.handleNav(event.currentTarget.dataset.screen, []);
  };

  render() {
    const { currentScreen, LogoOnTabletAndAbove, LogoOnMobile } = this.props;

    return (
      <Header withBurger className="header">
        <a
          href="https://fra.sika.com/fr/nouveautes-produits/mix-flow.html"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            className="header__brand-logo display-on-mobile"
            src={SikaLogoOnMobile}
            alt="logo-sika"
          />
          <img
            className="header__brand-logo display-on-tablet-and-above"
            src={SikaLogoOnTableAndAbove}
            alt="logo-sika"
          />
        </a>

        <h1>
          <img
            className="header__logo display-on-mobile"
            src={LogoOnMobile}
            alt="logo-mix-and-flow"
          />
          <img
            className="header__logo display-on-tablet-and-above"
            src={LogoOnTabletAndAbove}
            alt="logo-mix-and-flow"
          />
        </h1>

        {currentScreen !== "home" && (
          <ul className="header__nav">
            <li
              className="btn header__nav__item"
              data-screen="home"
              onClick={this.handleRestart}
            >
              <div>{restart}</div>
            </li>
          </ul>
        )}
      </Header>
    );
  }
}

export default MixAndFlowHeader;
