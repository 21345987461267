// npm imports
import React, { useState } from "react";
import withRouter from "../../hooks/withRouter";

// internal imports
import { uninstallModalText } from "../../data/mixAndFlowData/textData";
import { emptyCache, emptyLocalStorage } from "../../utils/uninstallAppUtils";

function UninstallModal({ backgroundImage, unauthorizeCurrentUser }) {
  const [message, setMessage] = useState("");

  const handleClick = () => {
    const isOnLine = navigator.onLine;
    // const isOnLine = false;

    if (!isOnLine) {
      setMessage(uninstallModalText.uninstallButtonOffLineTwice);
    } else {
      // vider le cache
      emptyCache();

      // vider le localstorage
      emptyLocalStorage();

      // l'utilisateur n'est plus autorisé
      unauthorizeCurrentUser();

      // rediriger vers / puisqu'on est sur /app
      this?.props?.history?.push("/authentification");
    }
  };

  return (
    <div id="modal" className="modal">
      <div
        className="modal__content"
        style={{ backgroundImage: `url(${backgroundImage})` }}
      >
        <div className="modal__content--specifics modal__content--uninstall">
          <div
            className="specifics__text"
            dangerouslySetInnerHTML={{
              __html: uninstallModalText.unsinstallText,
            }}
          />

          <span>{message}</span>

          <button
            id="modal__install-button"
            className="btn"
            onClick={handleClick}
          >
            {uninstallModalText.uninstallButtonOffLine}
          </button>
        </div>
      </div>
    </div>
  );
}

export default withRouter(UninstallModal);
