// npm imports
import React from "react";
import { Form } from "react-bootstrap";

import DeleteSvg from "../../assets/img/delete.svg";

const SelectInput = ({
  data,
  name,
  onChange,
  onDelete,
  selectedOption,
  text,
  withDelete,
}) => {
  const isFilled = !!selectedOption;

  return (
    <Form.Group className="form-group" controlId={name}>
      <Form.Label>
        {text.inputLabel}

        {withDelete && (
          <button
            type="button"
            onClick={onDelete}
            className="form-group__delete-button"
          >
            <img
              className="form-group__delete-image"
              src={DeleteSvg}
              alt="Supprimer le composant"
            />

            <span>Supprimer le composant</span>
          </button>
        )}
      </Form.Label>

      <div
        className={`form-group__select-container ${isFilled ? "isFilled" : ""}`}
      >
        <Form.Control
          as="select"
          onChange={onChange}
          name={name}
          value={selectedOption}
          size="lg"
        >
          {!!text.placeholder && (
            <option key={`${name}-0`} value="" disabled>
              {text.placeholder}
            </option>
          )}
          {data.map((item) => (
            <option key={`${name}-${item.name}`} value={item.name}>
              {item.name}
            </option>
          ))}
        </Form.Control>
      </div>
    </Form.Group>
  );
};

export default SelectInput;
