export const carbonFootprintData = [
  {
    productFamily: "Ciments",
    productFamilyCarbonFootprint: null,
    subProductFamily: false,
    products: [
      {
        productName: "CEM I",
        productCarbonFootprint: 0.748,
        subProducts: false,
      },
      {
        productName: "CEM II/A-L ou LL",
        productCarbonFootprint: 0.636,
        subProducts: false,
      },
      {
        productName: "CEM II/A-S A-M et A-V",
        productCarbonFootprint: 0.617,
        subProducts: false,
      },
      {
        productName: "CEM II/B-M ou B-S",
        productCarbonFootprint: 0.553,
        subProducts: false,
      },
      {
        productName: "CEM II/B-L ou B-LL",
        productCarbonFootprint: 0.544,
        subProducts: false,
      },
      {
        productName: "CEM II/C-M",
        productCarbonFootprint: 0.457,
        subProducts: false,
      },
      {
        productName: "CEM III/A NON PMES",
        productCarbonFootprint: 0.463,
        subProducts: false,
      },
      {
        productName: "CEM III/A PMES",
        productCarbonFootprint: 0.334,
        subProducts: false,
      },
      {
        productName: "CEM III/B",
        productCarbonFootprint: 0.313,
        subProducts: false,
      },
      {
        productName: "CEM III/C",
        productCarbonFootprint: 0.202,
        subProducts: false,
      },
      {
        productName: "CEM IV/A",
        productCarbonFootprint: 0.517,
        subProducts: false,
      },
      {
        productName: "CEM V/A",
        productCarbonFootprint: 0.475,
        subProducts: false,
      },
      {
        productName: "CEM VI",
        productCarbonFootprint: 0.451,
        subProducts: false,
      },
    ],
  },
  {
    productFamily: "Additions",
    productFamilyCarbonFootprint: null,
    subProductFamily: false,
    products: [
      {
        productName: "Cendres volantes",
        productCarbonFootprint: 0.042,
        subProducts: false,
      },
      {
        productName: "Filler calcaire",
        productCarbonFootprint: 0.043,
        subProducts: false,
      },
      {
        productName: "Fumée de silice",
        productCarbonFootprint: 0.354,
        subProducts: false,
      },
      {
        productName: "Laitier de haut fourneau",
        productCarbonFootprint: 0.102,
        subProducts: false,
      },
      {
        productName: "Métakaolin",
        productCarbonFootprint: 0.168,
        subProducts: false,
      },
    ],
  },
  {
    productFamily: "Sables",
    productFamilyCarbonFootprint: 0.0028,
    subProductFamily: false,
    products: false,
  },
  {
    productFamily: "Gravillons",
    productFamilyCarbonFootprint: 0.0028,
    subProductFamily: false,
    products: false,
  },
  {
    productFamily: "Adjuvants",
    productFamilyCarbonFootprint: null,
    subProductFamily: false,
    products: [
      {
        productName: "Plastifiant/Superplastifiant Catégorie A",
        productCarbonFootprint: 0.514,
        subProducts: false,
      },
      {
        productName: "Plastifiant/Superplastifiant Catégorie B",
        productCarbonFootprint: 0.689,
        subProducts: false,
      },
      {
        productName: "Plastifiant/Superplastifiant Catégorie C",
        productCarbonFootprint: 0.887,
        subProducts: false,
      },
      {
        productName: "Entraîneur d'air",
        productCarbonFootprint: 0.439,
        subProducts: false,
      },
      {
        productName: "Retardateur",
        productCarbonFootprint: 1.23,
        subProducts: false,
      },
      {
        productName: "Hydrofuge",
        productCarbonFootprint: 2.67,
        subProducts: false,
      },
      {
        productName: "Accélérateur de prise",
        productCarbonFootprint: 1.34,
        subProducts: false,
      },
      {
        productName: "Accélérateur de durcissement",
        productCarbonFootprint: 1.79,
        subProducts: false,
      },
    ],
  },
];

export const nullNewProduct = {
  choiceId: null,
  productFamily: null,
  productFamilyCarbonFootprint: null,
  subProductFamily: null,
  hasProducts: null,
  productName: null,
  productCarbonFootprint: null,
  subProducts: null,
  quantity: null,
};
