export const authorizedUsers = [
  { login: "apsulis", password: "sika2021" },
  { login: "begue.j", password: "sikamixandflow2021" },
  { login: "bertaud.v", password: "sikamixandflow2021" },
  { login: "bertrand.r", password: "sikamixandflow2021" },
  { login: "bettembot.f", password: "sikamixandflow2021" },
  { login: "bizoux.q", password: "sikamixandflow2021" },
  { login: "blanc.p", password: "sikamixandflow2021" },
  { login: "bonnet.w", password: "sikamixandflow2021" },
  { login: "bouaillon.d", password: "sikamixandflow2021" },
  { login: "bounouar.y", password: "sikamixandflow2021" },
  { login: "bousquet.p", password: "sikamixandflow2021" },
  { login: "cade.d", password: "sikamixandflow2021" },
  { login: "cambe.j", password: "sikamixandflow2021" },
  { login: "cazemajou.r", password: "sikamixandflow2021" },
  { login: "cheron.c", password: "sikamixandflow2021" },
  { login: "chesnais.v", password: "sikamixandflow2021" },
  { login: "chevallier.g", password: "sikamixandflow2021" },
  { login: "chignon.o", password: "sikamixandflow2021" },
  { login: "decroix.f", password: "sikamixandflow2021" },
  { login: "degrumelle.c", password: "sikamixandflow2021" },
  { login: "delasalle.jf", password: "sikamixandflow2021" },
  { login: "desveaux.p", password: "sikamixandflow2021" },
  { login: "dufour.s", password: "sikamixandflow2021" },
  { login: "dul.f", password: "sikamixandflow2021" },
  { login: "dullin.b", password: "sikamixandflow2021" },
  { login: "dutour.n", password: "sikamixandflow2021" },
  { login: "duval.m", password: "sikamixandflow2021" },
  { login: "etien.a", password: "sikamixandflow2021" },
  { login: "fournet.f", password: "sikamixandflow2021" },
  { login: "garant.b", password: "sikamixandflow2021" },
  { login: "goin.o", password: "sikamixandflow2021" },
  { login: "goubin.t", password: "sikamixandflow2021" },
  { login: "gremy.v", password: "sikamixandflow2021" },
  { login: "guet.t", password: "sikamixandflow2021" },
  { login: "hauchard.p", password: "sikamixandflow2021" },
  { login: "lafond.c", password: "sikamixandflow2021" },
  { login: "lamoussiere.f", password: "sikamixandflow2021" },
  { login: "lathuraz.a", password: "sikamixandflow2021" },
  { login: "lavenant.r", password: "sikamixandflow2021" },
  { login: "lavenant.r", password: "sikamixandflow2021" },
  { login: "lebon.s", password: "sikamixandflow2021" },
  { login: "lefur.c", password: "sikamixandflow2021" },
  { login: "leguern.f", password: "sikamixandflow2021" },
  { login: "lourtil.p", password: "sikamixandflow2021" },
  { login: "maring.t", password: "sikamixandflow2021" },
  { login: "marques.d", password: "sikamixandflow2021" },
  { login: "mathias.v", password: "sikamixandflow2021" },
  { login: "michaux.c", password: "sikamixandflow2021" },
  { login: "michelet.v", password: "sikamixandflow2021" },
  { login: "monge.j", password: "sikamixandflow2021" },
  { login: "morales.f", password: "sikamixandflow2021" },
  { login: "neau.g", password: "sikamixandflow2021" },
  { login: "neto.e", password: "sikamixandflow2021" },
  { login: "nigaud.j", password: "sikamixandflow2021" },
  { login: "nolot.l", password: "sikamixandflow2021" },
  { login: "noyret.p", password: "sikamixandflow2021" },
  { login: "ouallet.jj", password: "sikamixandflow2021" },
  { login: "petriol.p", password: "sikamixandflow2021" },
  { login: "picard.s", password: "sikamixandflow2021" },
  { login: "pierre.jc", password: "sikamixandflow2021" },
  { login: "quillien.lb", password: "sikamixandflow2021" },
  { login: "raoelison.v", password: "sikamixandflow2021" },
  { login: "renault.t", password: "sikamixandflow2021" },
  { login: "renaux.p", password: "sikamixandflow2021" },
  { login: "ride.f", password: "sikamixandflow2021" },
  { login: "roumeas.m", password: "sikamixandflow2021" },
  { login: "segalin.a", password: "sikamixandflow2021" },
  { login: "tesson.s", password: "sikamixandflow2021" },
  { login: "tuncer.s", password: "sikamixandflow2021" },
  { login: "vich.s", password: "sikamixandflow2021" },
];
