export function getFormulationCarbonFootprint(formulation) {
  if (!formulation?.length) {
    return 0;
  }

  return formulation.reduce((acc, productData) => {
    const { productCarbonFootprint, productFamilyCarbonFootprint, quantity } =
      productData;

    const parsedQuantity = parseFloat(quantity.replace(',', '.'), 10);

    if (!!productCarbonFootprint) {
      return acc + parsedQuantity * productCarbonFootprint;
    } else if (!!productFamilyCarbonFootprint) {
      return acc + parsedQuantity * productFamilyCarbonFootprint;
    }

    return acc;
  }, 0);
}
