// npm imports
import React, { Component } from 'react';
import { Button } from 'react-bootstrap';

// intern imports.
import { homeText } from '../../data/co2AppData/textData';

// local imports
class CarbonFootprintHome extends Component {
	render() { 
		const { handleCtaClick } = this.props;

		return (
			<section className="home">
				<div className="home__cta home__cta--carbon-footprint">
					{
						homeText.ctaButtons.map((button) => (
							<Button
								key={ button.text }
								size="lg"
								className="home__cta__item"
								data-screen={button.screen}
								onClick={handleCtaClick}
								dangerouslySetInnerHTML={{
									__html: button.text ,
								}}
							/>
						))
					}
				</div>
			</section>
		);
	}
}
 
export default CarbonFootprintHome;