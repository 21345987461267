import React from "react";

const SingleFormulationResult = ({ result, title, comparative }) => {
  return (
    <>
      {comparative && <h3>{title}</h3>}

      <p className="formulation-result">
        {Math.round(result)} kg/m<sup>3</sup>
      </p>
    </>
  );
};

export default SingleFormulationResult;
