/**
 * PRODUCTS
 */
export const sikaProductNames = {
  fast: "Viscoflow 900 Fast",
  power: "Viscoflow 800 Power",
  safe: "Viscoflow 300 Safe",
  soft: "Viscoflow 400 Soft",
  timer: "Viscoflow 200 Timer",
};

export const sikaProducts = [
  {
    name: "Plastiment Flow-30",
    doses: {
      power: 30 / 100,
      soft: 0 / 100,
      timer: 20 / 100,
    },
  },
  {
    name: "Plastiment Flow-76",
    doses: {
      power: 35 / 100,
      soft: 0 / 100,
      timer: 20 / 100,
    },
  },
  {
    name: "Sikaplast Techno 117",
    doses: {
      power: 25 / 100,
      safe: 35 / 100,
      timer: 10 / 100,
    },
    alternativeChoices: [
      {
        power: 15 / 100,
        soft: 45 / 100,
        timer: 15 / 100,
      },
    ],
  },
  {
    name: "Sikaplast Techno 227",
    doses: {
      power: 40 / 100,
      safe: 40 / 100,
      timer: 10 / 100,
    },
    alternativeChoices: [
      {
        power: 30 / 100,
        soft: 50 / 100,
        timer: 10 / 100,
      },
      {
        fast: 25 / 100,
        safe: 55 / 100,
        timer: 10 / 100,
      },
    ],
  },
  {
    name: "Sikaplast Techno 348",
    doses: {
      power: 55 / 100,
      soft: 0 / 100,
      timer: 15 / 100,
    },
    alternativeChoices: [
      {
        fast: 35 / 100,
        safe: 20 / 100,
        timer: 15 / 100,
      },
    ],
  },
  // {
  //   name: "Sikaplast Techno 45",
  //   doses: {
  //     power: 75 / 100,
  //     soft: 0 / 100,
  //     timer: 12 / 100,
  //   },
  // },
  {
    name: "Sikaplast Techno 80",
    doses: {
      power: 35 / 100,
      soft: 35 / 100,
      timer: 10 / 100,
    },
    alternativeChoices: [
      {
        power: 45 / 100,
        safe: 20 / 100,
        timer: 10 / 100,
      },
    ],
  },
  {
    name: "Sikaplast Techno 90",
    doses: {
      power: 20 / 100,
      soft: 65 / 100,
      timer: 0 / 100,
    },
    alternativeChoices: [
      {
        power: 35 / 100,
        safe: 50 / 100,
        timer: 0 / 100,
      },
      {
        fast: 15 / 100,
        safe: 70 / 100,
        timer: 0 / 100,
      },
    ],
  },
  {
    name: "Sikaplast Technoflow",
    doses: {
      power: 40 / 100,
      soft: 25 / 100,
      timer: 0 / 100,
    },
    alternativeChoices: [
      {
        power: 50 / 100,
        safe: 15 / 100,
        timer: 0 / 100,
      },
    ],
  },
  {
    name: "Sika Viscocrete Tempo 10",
    doses: {
      power: 100 / 100,
      soft: 0 / 100,
      timer: 0 / 100,
    },
    alternativeChoices: [
      {
        fast: 60 / 100,
        safe: 40 / 100,
        timer: 0 / 100,
      },
    ],
  },
  {
    name: "Sika Viscocrete Tempo 11",
    doses: {
      power: 50 / 100,
      soft: 45 / 100,
      timer: 0 / 100,
    },
    alternativeChoices: [
      {
        fast: 30 / 100,
        safe: 65 / 100,
        timer: 0 / 100,
      },
    ],
  },
  {
    name: "Sika Viscocrete Tempo 12",
    doses: {
      power: 20 / 100,
      soft: 75 / 100,
      timer: 0 / 100,
    },
    alternativeChoices: [
      {
        power: 35 / 100,
        safe: 55 / 100,
        timer: 0 / 100,
      },
    ],
  },
  {
    name: "Sika Viscocrete Tempo 413",
    doses: {
      power: 35 / 100,
      safe: 45 / 100,
      timer: 0 / 100,
    },
    alternativeChoices: [
      {
        power: 10 / 100,
        soft: 65 / 100,
        timer: 0 / 100,
      },
    ],
  },
  {
    name: "Sika Viscocrete Tempo 483",
    doses: {
      power: 20 / 100,
      soft: 75 / 100,
      timer: 0 / 100,
    },
    alternativeChoices: [
      {
        power: 35 / 100,
        safe: 55 / 100,
        timer: 0 / 100,
      },
    ],
  },
  {
    name: "Sika Viscocrete Tempo 533",
    doses: {
      power: 35 / 100,
      soft: 35 / 100,
      timer: 0 / 100,
    },
    alternativeChoices: [
      {
        fast: 20 / 100,
        safe: 50 / 100,
        timer: 0 / 100,
      },
    ],
  },
  {
    name: "Sika Viscocrete Tempo 653",
    doses: {
      power: 90 / 100,
      soft: 10 / 100,
      timer: 0 / 100,
    },
    alternativeChoices: [
      {
        fast: 55 / 100,
        safe: 45 / 100,
        timer: 0 / 100,
      },
    ],
  },
];

export const cementStep = {
  min: 0,
  max: 2,
};

/**
 * SIMULATOR
 */
/***
 * input
 ***/
export const stepNameList = [
  {
    name: "exposure-classes",
    text: "Classe d'exposition",
  },
  {
    name: "concrete",
    text: "Type de ciment",
  },
  {
    name: "alcalin",
    text: "Taux d'alcalin",
  },
  {
    name: "aggregates",
    text: "Type de granulats",
  },
  {
    name: "consistency",
    text: "Consistance",
  },
  {
    name: "temperature",
    text: "Température",
  },
];

/***
 * Similator select input options
 ***/
export const exposureClasses = [
  {
    name: "XC1",
    calculId: 1,
  },
  {
    name: "XC2",
    calculId: 1,
  },
  {
    name: "XC3",
    calculId: 2,
  },
  {
    name: "XC4",
    calculId: 2,
  },
  {
    name: "XS1",
    calculId: 3,
  },
  {
    name: "XS2",
    calculId: 3,
  },
  {
    name: "XS3",
    calculId: 4,
  },
  {
    name: "XD1",
    calculId: 2,
  },
  {
    name: "XD2",
    calculId: 3,
  },
  {
    name: "XD3",
    calculId: 4,
  },
  {
    name: "XF1",
    calculId: 2,
  },
  {
    name: "XF2",
    calculId: 6,
  },
  {
    name: "XF3",
    calculId: 6,
  },
  {
    name: "XF4",
    calculId: 7,
  },
  {
    name: "XA1",
    calculId: 3,
  },
  {
    name: "XA2",
    calculId: 4,
  },
  {
    name: "XA3",
    calculId: 5,
  },
];

export const concreteSelectOptions = [
  {
    name: "CEM I",
  },
  {
    name: "CEM II",
  },
  {
    name: "CEM III",
  },
  {
    name: "CEM IV",
  },
  {
    name: "CEM V",
  },
  {
    name: "CEM VI",
  },
];

export const alcalinStep = {
  min: 0.1,
  max: 1.2,
};

export const aggregatesSelectOptions = [
  {
    name: "Roulé",
  },
  {
    name: "Concassé",
  },
  {
    name: "Semi-concassé",
  },
];

export const consistencySelectOptions = [
  {
    name: "S3",
  },
  {
    name: "S4",
  },
  {
    name: "BAP",
  },
];

export const temperatureSelectOptions = [
  {
    name: "10°C",
  },
  {
    name: "20°C",
  },
  {
    name: "30°C",
  },
];
