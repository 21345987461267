// npm imports
import React, { Component } from "react";
import { Link } from "react-router-dom";
import withRouter from "../../hooks/withRouter";


// intern imports.
import { launcherText } from "../../data/launcherTextData";
// components
import AppExpiration from "../AppExpiration";
import FirstVisitModal from "../FirstVisitModal";
import InstallModal from "../InstallModal";

// assets
import Skatepark from "../../assets/img/MixAndFlow_Visuel_Skatepark.jpg";

// utils
import SikaHeader from "../SikaHeader";

// local imports

class LandingPage extends Component {
  state = {
    firstVisit: true,
    installModalIsOpen: true,
  };

  componentDidMount() {
    this.updateStateFromLocalstorage();
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.currentScreen !== this.state.currentScreen) {
      window.scrollTo(0, 0);
    }
  }

  updateStateFromLocalstorage = () => {
    const pwaInstallDate = this.getLocalstorage("pwaInstallDate") ?? null;
    const firstVisit = this.getLocalstorage("firstVisit") ? false : true;

    this.setState({
      firstVisit,
      installModalIsOpen: !pwaInstallDate,
    });
  };

  getLocalstorage = (item) => {
    return localStorage.getItem(item);
  };

  closeFirstVisitModal = () => {
    localStorage.setItem("firstVisit", false);

    this.setState({ firstVisit: false });
  };

  closeInstallModal = () => {
    this.setState({ installModalIsOpen: false });
  };

  render() {
    const { firstVisit, installModalIsOpen } = this.state;
    const { unauthorizeCurrentUser } = this.props;

    if (firstVisit) {
      return (
        <div className="app">
          <FirstVisitModal closeModal={this.closeFirstVisitModal} />
        </div>
      );
    }

    return (
      <div className="app launcher-app">
        <InstallModal
          backgroundImage={Skatepark}
          closeModal={this.closeInstallModal}
          updateLocalstorage={this.updateStateFromLocalstorage}
          visible={installModalIsOpen}
        />

        <AppExpiration unauthorizeCurrentUser={unauthorizeCurrentUser} />

        <SikaHeader withBurger />

        <main className="launcher-app__content">
          <h1>{launcherText.title}</h1>

          <nav>
            {launcherText &&
              launcherText.ctaButtons.length &&
              launcherText.ctaButtons.map((button) => {
                return (
                  <Link
                    key={button.text}
                    className="btn btn-lg"
                    to={button.link}
                  >
                    {button.text}
                  </Link>
                );
              })}
          </nav>
        </main>
      </div>
    );
  }
}

export default withRouter(LandingPage);
