import { useState } from "react";
import { Link } from "react-router-dom";

// intern imports.
import { launcherText } from "../../data/launcherTextData";

export default function BurgerMenu({ className }) {
  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => setIsOpen(!isOpen);

  return (
    <nav
      id="burger-menu"
      role="navigation"
      className={`${className || ""}${isOpen ? " open" : " closed"}`}
      aria-label="Menu"
    >
      <button aria-expanded={isOpen} onClick={toggle}>
        <span></span>
        <span></span>
        <span></span>
      </button>

      <ul id="menu">
        <li>
          <Link to="/" onClick={toggle}>
            Accueil
          </Link>
        </li>

        {launcherText &&
          launcherText.ctaButtons.length &&
          launcherText.ctaButtons.map((button) => {
            return (
              <li>
                <Link key={button.text} to={button.link} onClick={toggle}>
                  {button.text}
                </Link>
              </li>
            );
          })}

        <li className="menu__bottom-item">
          <Link to="/mentions-legales" onClick={toggle}>
            Mentions légales
          </Link>
        </li>
      </ul>
    </nav>
  );
}
