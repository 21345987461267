// npm imports
import React, { Component } from "react";
import withRouter from "../../hooks/withRouter";
import { history } from "../../hooks/history";
import UninstallModal from "../UninstallModal";
import { emptyCache, emptyLocalStorage } from "../../utils/uninstallAppUtils";

class AppExpiration extends Component {


  state = {
    pwaExpirationDate: null,
    pwaInstallDate: null,
  };

  componentDidMount() {
    this.updateStateFromLocalstorage();
  }

  updateStateFromLocalstorage = () => {
    const pwaInstallDate = this.getLocalstorage("pwaInstallDate") ?? null;
    const pwaExpirationDate = this.getLocalstorage("pwaExpirationDate") ?? null;
    const firstVisit = this.getLocalstorage("firstVisit") ? false : true;

    this.setState({
      firstVisit,
      installModalIsOpen: !pwaInstallDate,
      pwaInstallDate,
      pwaExpirationDate,
    });
  };

  getLocalstorage = (item) => {
    return localStorage.getItem(item);
  };

  render() {
    const { pwaExpirationDate } = this.state;
    const { unauthorizeCurrentUser } = this.props;

    // Calculator expiration
    const today = new Date();
    const pwaExpirationDateJsDate =
      pwaExpirationDate &&
      (pwaExpirationDate instanceof Date
        ? pwaExpirationDate
        : new Date(
            pwaExpirationDate?.replace('"', "").replace("Z", "").split(".")[0]
          ));
    const isOnLine = navigator.onLine;
    // const isOnLine = false;
    
    if (!!pwaExpirationDate && pwaExpirationDateJsDate < today) {
      if (!isOnLine) {
        return (
          <div className="app">
            <UninstallModal unauthorizeCurrentUser={unauthorizeCurrentUser} />
          </div>
        );
      } else {
        // vider le cache
        emptyCache();

        // vider le localstorage
        emptyLocalStorage();

        // l'utilisateur n'est plus autorisé
        unauthorizeCurrentUser();

        // rediriger vers / puisqu'on est sur /app
        history("/authentification");
      }
    }

    return null;
  }
}

export default withRouter(AppExpiration);
