/**
 * https://stackoverflow.com/questions/62349598/how-to-really-clear-cache-in-pwa
 */
export const emptyCache = () => {
  if ("serviceWorker" in navigator) {
    caches.keys().then((cacheNames) => {
      cacheNames.forEach((cacheName) => {
        caches.delete(cacheName);
      });
    });
  }
};

/**
 * https://developer.mozilla.org/fr/docs/Web/API/Storage/clear
 */
export const emptyLocalStorage = () => {
  localStorage.clear();
};
