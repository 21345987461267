import { calculationData } from "../../data/mixAndFlowData/calculationData";
import { exposureClasses } from "../../data/mixAndFlowData/selectData";

export function semiCrushedCalculationFunction(userChoices) {
  const userChoicesWithoutAggregates = userChoices.filter(
    (choice) => choice.stepName !== "aggregates"
  );

  // calculation if "Roulé"
  const userChoicesIfRolledSand = [
    ...userChoicesWithoutAggregates,
    {
      stepName: "aggregates",
      value: "Roulé",
    },
  ];
  const calculationIfRolledSand = calculationFunction(userChoicesIfRolledSand);

  // calculation if "Concassé"
  const userChoicesIfCrushed = [
    ...userChoicesWithoutAggregates,
    {
      stepName: "aggregates",
      value: "Concassé",
    },
  ];
  const calculationIfCrushed = calculationFunction(userChoicesIfCrushed);

  // basic calculation average
  const power =
    (calculationIfRolledSand.power + calculationIfCrushed.power) / 2;
  const soft = (calculationIfRolledSand.soft + calculationIfCrushed.soft) / 2;
  const timer = calculationIfCrushed.timer;

  return { power, soft, timer };
}

export function calculationFunction(userChoices) {
  // basic calculation
  const basicCalculation = getBasicCalculation(userChoices);
  let power = basicCalculation.power;
  let soft = basicCalculation.power;
  let timer = basicCalculation.power;

  // additional rules
  const additionalRulesCalculation = applyAdditionalRules(
    userChoices,
    basicCalculation
  );
  power = additionalRulesCalculation.power;
  soft = additionalRulesCalculation.soft;
  timer = additionalRulesCalculation.timer;

  return { power, soft, timer };
}

// BASIC CALCULATION
function getBasicCalculation(userChoices) {
  const calculId = getCurrentCalculationId(userChoices);
  const currentCalculationData = calculationData.find(
    (calculation) => calculation.calculID === calculId
  );

  // basic calculation
  let power = getProductDose(currentCalculationData.power, userChoices);
  let soft = getProductDose(currentCalculationData.soft, userChoices);
  let timer = getProductDose(currentCalculationData.timer, userChoices);

  return { power, soft, timer };
}

export function getCurrentCalculationId(userChoices) {
  const userExposureClass = userChoices.find(
    (choice) => choice.stepName === "exposure-classes"
  ).value;
  const exposureClassCalculId = exposureClasses.find(
    (exposureClass) => exposureClass.name === userExposureClass
  ).calculId;

  return exposureClassCalculId;
}

function getProductDose(calculationVariables, userChoices) {
  // consistency
  const userChoiceConsistency = userChoices.find(
    (choice) => choice.stepName === "consistency"
  ).value;
  let s4ConsistencyMultiplier = 0;
  let bapConsistencyMultiplier = 0;
  if (userChoiceConsistency === "S4") {
    s4ConsistencyMultiplier = 1;
  } else if (userChoiceConsistency === "BAP") {
    bapConsistencyMultiplier = 1;
  }

  // aggregates
  const userChoiceAggregates = userChoices.find(
    (choice) => choice.stepName === "aggregates"
  ).value;
  const aggregatesMultiplier = userChoiceAggregates === "Concassé" ? 1 : 0;

  // alcalin
  const userChoiceConcrete = userChoices.find(
    (choice) => choice.stepName === "concrete"
  ).value;
  const alcalinMultiplier =
    userChoiceConcrete === "CEM III" ||
    userChoiceConcrete === "CEM V" ||
    userChoiceConcrete === "CEM VI"
      ? 0.3
      : parseFloat(
          userChoices
            .find((choice) => choice.stepName === "alcalin")
            .value.replace(",", ".")
        );

  // dose calculation
  const dose =
    calculationVariables.constante +
    s4ConsistencyMultiplier * calculationVariables.s4Consistency +
    bapConsistencyMultiplier * calculationVariables.bapConsistency +
    aggregatesMultiplier * calculationVariables.aggregates +
    alcalinMultiplier * calculationVariables.alcalin;

  return dose > 0 ? dose : 0;
}

// ADDITIONAL RULES
function applyAdditionalRules(userChoices, basicCalculation) {
  // get basic calculation
  let power = basicCalculation.power;
  let soft = basicCalculation.soft;
  let timer = basicCalculation.timer;

  // apply rule
  const userTemperature = userChoices.find(
    (choice) => choice.stepName === "temperature"
  ).value;

  if (userTemperature === "10°C") {
    power = power + 0.05;
    soft = soft - 0.1;
    timer = 0;
  } else if (userTemperature === "30°C") {
    soft = soft + 0.1;
    timer = 0.2;
  }

  if (Math.round(soft * 100) / 100 < 0.05) {
    soft = 0;
  }

  if (Math.round(power * 100) / 100 < 0.05) {
    power = 0;
  }

  return { power, soft, timer };
}
