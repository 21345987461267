// npm imports
import React, { Component } from "react";
import { Form } from "react-bootstrap";

// intern imports.
import SelectInput from "../SelectInput";
import {
  aggregatesStepText,
  alcalinStepText,
  consistencyStepText,
  concreteStepText,
  exposureClassesText,
  formSubmitButtonText,
  simulatorTitle,
  temperatureStepText,
} from "../../data/mixAndFlowData/textData";
import {
  aggregatesSelectOptions,
  alcalinStep,
  consistencySelectOptions,
  concreteSelectOptions,
  exposureClasses,
  temperatureSelectOptions,
  stepNameList,
} from "../../data/mixAndFlowData/selectData";
import { sortArrayByName } from "../../utils/arrayUtils";

// local imports

class MixAndFlowSimulator extends Component {
  state = {
    alcalinHasError: false,
    authorizeValidation: false,
    userChoices: [],
  };

  componentDidMount() {
    const { userChoices } = this.props;

    this.setState({ userChoices }, () => this.checkFormValidity());
  }

  setUserChoices = (event) => {
    const { userChoices } = this.state;
    const name = event.currentTarget.name;
    const value = event.currentTarget.value;

    if (name === "alcalin") {
      this.checkAlcalinStep(value);
    }

    let newUserChoices = [];

    if (userChoices.find((choice) => choice.stepName === name)) {
      newUserChoices = userChoices.filter((choice) => choice.stepName !== name);
      newUserChoices = [...newUserChoices, { stepName: name, value }];
    } else {
      newUserChoices = [...this.state.userChoices, { stepName: name, value }];
    }

    this.setState({ userChoices: newUserChoices }, () =>
      this.checkFormValidity()
    );
  };

  checkAlcalinStep = (value) => {
    const { alcalinHasError } = this.state;

    if (value < alcalinStep.min || value > alcalinStep.max) {
      this.setState({ alcalinHasError: true });
    } else if (alcalinHasError) {
      this.setState({ alcalinHasError: false });
    }
  };

  /**
   * check if the form is valid
   *
   * to be valid :
   * alcalinHasError must be false
   * userChoices state needs to contain all stepName necessary to deal with the calculation
   * and that it has a value
   *
   * change authorizeValidation state to true if the form is valid
   */
  checkFormValidity = () => {
    const { alcalinHasError, userChoices } = this.state;
    const savedUserChoices = userChoices.map((choice) => choice.stepName);
    const mandatoryStepNames = stepNameList.map((stepName) => stepName.name);
    let isValid = false;

    if (alcalinHasError) {
      return this.setState({ authorizeValidation: isValid });
    }

    isValid = mandatoryStepNames
      .map((stepName) => {
        if (savedUserChoices.includes(stepName)) {
          const valueIsNotEmpty =
            userChoices.find((userChoice) => userChoice.stepName === stepName)
              .value !== "";

          return valueIsNotEmpty;
        }

        return false;
      })
      .every((choice) => choice === true);

    this.setState({ authorizeValidation: isValid });
  };

  handleFormSubmit = (event) => {
    event.preventDefault();

    this.props.handleResultValidation(
      event.currentTarget.dataset.screen,
      this.state.userChoices
    );
  };

  render() {
    const { alcalinHasError, authorizeValidation, userChoices } = this.state;

    return (
      <section className="simulator">
        <h2 className="simulator__title">{simulatorTitle}</h2>

        <Form
          className="simulator__form complete-form"
          data-screen="simulator-results"
          onSubmit={this.handleFormSubmit}
          method="post"
        >
          <SelectInput
            key="exposure-classes"
            data={sortArrayByName(exposureClasses)}
            name="exposure-classes"
            onChange={this.setUserChoices}
            selectedOption={
              userChoices.find(
                (choice) => choice.stepName === "exposure-classes"
              )?.value ?? ""
            }
            text={exposureClassesText}
          />

          <SelectInput
            key="concrete"
            data={sortArrayByName(concreteSelectOptions)}
            name="concrete"
            onChange={this.setUserChoices}
            selectedOption={
              userChoices.find((choice) => choice.stepName === "concrete")
                ?.value ?? ""
            }
            text={concreteStepText}
          />

          <Form.Group className="form-group">
            <Form.Label>{alcalinStepText.inputLabel}</Form.Label>
            <Form.Control
              size="lg"
              type="text"
              name="alcalin"
              placeholder={alcalinStepText.placeholder}
              isInvalid={alcalinHasError}
              value={
                userChoices.find((choice) => choice.stepName === "alcalin")
                  ?.value ?? ""
              }
              onChange={this.setUserChoices}
              className={
                alcalinHasError
                  ? "hasError"
                  : userChoices.find((choice) => choice.stepName === "alcalin")
                  ? "isFilled"
                  : ""
              }
            />
            <Form.Control.Feedback type="invalid">
              {alcalinStepText.small}
            </Form.Control.Feedback>
          </Form.Group>

          <SelectInput
            key="aggregates"
            data={sortArrayByName(aggregatesSelectOptions)}
            name="aggregates"
            onChange={this.setUserChoices}
            selectedOption={
              userChoices.find((choice) => choice.stepName === "aggregates")
                ?.value ?? ""
            }
            text={aggregatesStepText}
          />

          <SelectInput
            key="consistency"
            data={sortArrayByName(consistencySelectOptions)}
            name="consistency"
            onChange={this.setUserChoices}
            selectedOption={
              userChoices.find((choice) => choice.stepName === "consistency")
                ?.value ?? ""
            }
            text={consistencyStepText}
          />

          <SelectInput
            key="temperature"
            data={sortArrayByName(temperatureSelectOptions)}
            name="temperature"
            onChange={this.setUserChoices}
            selectedOption={
              userChoices.find((choice) => choice.stepName === "temperature")
                ?.value ?? ""
            }
            text={temperatureStepText}
          />

          <button className={`btn`} disabled={!authorizeValidation}>
            {authorizeValidation
              ? formSubmitButtonText.isValid
              : formSubmitButtonText.isNotValid}
          </button>
        </Form>
      </section>
    );
  }
}

export default MixAndFlowSimulator;
