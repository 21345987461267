// npm imports
import React, { Component } from "react";
import withRouter from "../../hooks/withRouter";

// intern imports.
import AppExpiration from "../AppExpiration";
import CarbonFootprintFormulationScreen from "../CarbonFootprintFormulationScreen";
import SikaHeader from "../SikaHeader";
import CarbonFootprintHome from "../CarbonFootprintHome";
import CarbonFootprintResult from "../CarbonFootprintResult";
import { nullNewProduct } from "../../data/co2AppData/carbonFootprintData";
import { screen } from "../../data/co2AppData/textData";

class CarbonFootprintApp extends Component {
  state = {
    canAddAProduct: true,
    currentScreen: screen.home,
    initialFormulation: [],
    secondFormulation: [],
  };

  // LIFE CYCLES
  componentDidUpdate(prevProps, prevState) {
    const { initialFormulation, secondFormulation } = this.state;
    const {
      initialFormulation: prevInitialFormulation,
      secondFormulation: prevSecondFormulation,
    } = prevState;

    if (initialFormulation !== prevInitialFormulation) {
      const canAddAProduct = this.checkLastProduct(initialFormulation);
      this.setState({ canAddAProduct });
    }

    if (secondFormulation !== prevSecondFormulation) {
      const canAddAProduct = this.checkLastProduct(secondFormulation);
      this.setState({ canAddAProduct });
    }
  }

  checkLastProduct = (formulation) => {
    let canAddAProduct = true;

    if (formulation.length) {
      const lastProduct = formulation[formulation.length - 1];

      if (
        !lastProduct.quantity ||
        (lastProduct.hasProducts && !lastProduct.productName)
      ) {
        return false;
      }
    }

    return canAddAProduct;
  };

  // RESET APP
  resetState = () => {
    this.setState({
      canAddAProduct: true,
      currentScreen: screen.home,
      initialFormulation: [],
      secondFormulation: [],
    });
  };

  // SCREEN SELECTOR
  changeCurrentScreen = (event) => {
    this.setState({
      currentScreen: event.currentTarget.dataset.screen,
    });
  };

  // FORMULATIONS
  /**
   * update state.initialFormulation on data change
   *
   * @param {initialeFormulation} initialFormulation
   */
  changeInitialFormulationState = (initialFormulation) => {
    this.setState({
      initialFormulation,
    });
  };

  /**
   * update state.secondFormulation on data change
   *
   * @param {initialeFormulation} secondFormulation
   */

  changeSecondFormulationState = (secondFormulation) => {
    this.setState({
      secondFormulation,
    });
  };

  /**
   * add a product to formulation used by currentScreen
   */
  updateFormulationWithNewProduct = (event) => {
    event.preventDefault();

    const { currentScreen, initialFormulation, secondFormulation } = this.state;

    if (
      currentScreen === screen.diagnosticTest ||
      currentScreen === screen.comparativeReportFormulation1
    ) {
      const newInitialFormulation = this.getNewFormulation(initialFormulation);

      this.setState({ initialFormulation: newInitialFormulation });
    } else if (currentScreen === screen.comparativeReportFormulation2) {
      const newSecondFormulation = this.getNewFormulation(secondFormulation);

      this.setState({ secondFormulation: newSecondFormulation });
    }
  };

  /**
   * add new product to current formulation
   *
   * @param {array} formulation
   * @returns {array} formulation with new product
   */
  getNewFormulation = (formulation) => {
    let newProduct = nullNewProduct;
    let choiceId = 0;

    // incrémenter l'id du produit pour formulation 1
    choiceId = this.getNextChoiceId(formulation);

    // ajouter l'id au nouveau
    newProduct = { ...newProduct, choiceId };

    const newFormulation = [...formulation, newProduct];

    return newFormulation;
  };

  /**
   * increment choiceId of new product
   *
   * @param {array} formulation
   * @returns {number} choiceId
   */
  getNextChoiceId = (formulation) => {
    let choiceId = 1;

    if (formulation.length > 0) {
      choiceId = formulation[formulation.length - 1].choiceId + 1;
    }

    return choiceId;
  };

  // RENDER
  render() {
    const {
      canAddAProduct,
      currentScreen,
      initialFormulation,
      secondFormulation,
    } = this.state;
    const { unauthorizeCurrentUser } = this.props;

    return (
      <div className="app carbon-footprint-app">
        <AppExpiration unauthorizeCurrentUser={unauthorizeCurrentUser} />

        <SikaHeader withBurger />

        <main className="main main--carbon-footprint">
          {/* SCREEN SELECTOR */}
          {currentScreen === screen.home && (
            <CarbonFootprintHome handleCtaClick={this.changeCurrentScreen} />
          )}

          {(currentScreen === screen.diagnosticTest ||
            currentScreen === screen.comparativeReportFormulation1) && (
            <CarbonFootprintFormulationScreen
              canAddAProduct={canAddAProduct}
              changeCurrentScreen={this.changeCurrentScreen}
              changeFormulationState={this.changeInitialFormulationState}
              currentScreen={currentScreen}
              formulation={initialFormulation}
              updateFormulationWithNewProduct={
                this.updateFormulationWithNewProduct
              }
            />
          )}

          {currentScreen === screen.comparativeReportFormulation2 && (
            <CarbonFootprintFormulationScreen
              canAddAProduct={canAddAProduct}
              changeCurrentScreen={this.changeCurrentScreen}
              changeFormulationState={this.changeSecondFormulationState}
              currentScreen={currentScreen}
              formulation={secondFormulation}
              updateFormulationWithNewProduct={
                this.updateFormulationWithNewProduct
              }
            />
          )}

          {(currentScreen === screen.diagnosticTestResult ||
            currentScreen === screen.comparativeReportResult) && (
            <CarbonFootprintResult
              changeCurrentScreen={this.changeCurrentScreen}
              currentScreen={currentScreen}
              initialFormulation={initialFormulation}
              resetState={this.resetState}
              secondFormulation={secondFormulation}
            />
          )}
        </main>
      </div>
    );
  }
}

export default withRouter(CarbonFootprintApp);
