// npm imports
import React from "react";

// internal imports
import { resultsPage } from "../../data/mixAndFlowData/textData";

const MixAndFlowResults = ({
  alternativeChoices,
  currentCementPortion,
  dosage,
  isSimulator,
  productNames,
}) => {
  const dosageKeys = Object.keys(dosage);

  const multiplier =
    typeof currentCementPortion === "string"
      ? parseFloat(currentCementPortion.replace(",", "."))
      : parseFloat(currentCementPortion);

  return (
    <>
      <h3 dangerouslySetInnerHTML={{ __html: resultsPage.dosageTitle }} />

      <div className="first-choice">
        {dosageKeys.map((key) => {
          let dose = dosage[key];
          if (!isSimulator) {
            if (!multiplier) {
              dose = 0.0;
            } else {
              dose = Math.round(dosage[key] * multiplier * 100) / 100;
            }
          }
          return (
            <div className="results-dose__item">
              <span className="results-dose__item-name">
                {productNames[key]} :{" "}
              </span>

              <p className="results-dose__item-dose">
                <strong>{dose.toFixed(2)}</strong>
              </p>
            </div>
          );
        })}
      </div>

      <hr />

      {!!alternativeChoices && 
        !!alternativeChoices.length && 
        alternativeChoices.length > 1 && (
        <h4
          className="mt-2"
          dangerouslySetInnerHTML={{ __html: resultsPage.equivalentTitle }}
        />
      )}

      {!!alternativeChoices && 
        !!alternativeChoices.length && 
        alternativeChoices.length === 1 && (
        <h4
          className="mt-2"
          dangerouslySetInnerHTML={{ __html: resultsPage.equivalentTitleSingular }}
        />
      )}

      {!!currentCementPortion &&
        !!alternativeChoices &&
        !!alternativeChoices.length &&
        alternativeChoices.map((alternativeChoice, index) => {
          const values = Object.values(alternativeChoice);

          // si toutes les valeurs sont à 0 => on n'a pas d'équivalent pour cette entrée
          if (values.every((value) => value === 0)) {
            return null;
          }
          // sinon on a des équivalents
          else {
            const keys = Object.keys(alternativeChoice);

            return (
              <>
                <div key={`choice-${index}`}>{`Choix alternatif ${ alternativeChoices.length > 1 ?
                  index + 1 : ''
                } - Dosages indicatifs :`}</div>

                {keys.map((key) => {
                  return (
                    <div
                      key={`choice-${index}-${key}`}
                      className="results-dose__item"
                    >
                      <span className="results-dose__item-name">
                        {productNames[key]} :{" "}
                      </span>

                      <p className="results-dose__item-dose">
                        <strong>
                          {!multiplier
                            ? "O.OO"
                            : (
                                Math.round(
                                  alternativeChoice[key] * multiplier * 100
                                ) / 100
                              ).toFixed(2)}
                        </strong>
                      </p>
                    </div>
                  );
                })}
              </>
            );
          }
        })}
    </>
  );
};

export default MixAndFlowResults;
