import React from "react";

import { addProductButtonText } from "../../data/co2AppData/textData";

const AddProductButton = ({
  canAddAProduct,
  updateFormulationWithNewProduct,
}) => {
  return (
    <button
      className="btn btn--secondary btn--add"
      disabled={!canAddAProduct}
      onClick={updateFormulationWithNewProduct}
    >
      {canAddAProduct ? (
        <>
          <span>+</span>
          {addProductButtonText.enabled}
        </>
      ) : (
        addProductButtonText.disabled
      )}
    </button>
  );
};

export default AddProductButton;
