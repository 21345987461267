/**
 * get the next second monday from today
 * which is the app expiration day
 * it has to be during the night
 *
 * @param {date} today
 * @returns {date} pwaExpirationDate
 */
export function getExpirationDate(today) {
  const dayOfTheWeek = today.getDay();
  const nextSecondMondayFromNow = today.setDate(
    today.getDate() + 7 + 7 - dayOfTheWeek + 1
  );
  const expirationDateHours = new Date(nextSecondMondayFromNow).setHours(1);
  const expirationDateMin = new Date(expirationDateHours).setMinutes(0);
  const expirationDate = new Date(expirationDateMin).setSeconds(0);

  return new Date(expirationDate);
}

// ! TODO : supprimer cette fonction pour utiliser celle du dessus
// export function getExpirationDate(today) {
//   const expirationDateHours = new Date(
//     // new Date(today).setHours(today.getHours() + 4)
//     new Date(today).setHours(today.getHours() + 2)
//   );

//   // dans 4 heures GMT donc 2
//   // const expirationDate = expirationDateHours;

//   // dans 30 secondes en commentant ci-dessus
//   const expirationDate = new Date(expirationDateHours).setSeconds(
//     expirationDateHours.getSeconds() + 10
//   );

//   // à et quart dans l'heure qui suit en passant expirationDateHours à 2 + décommenter ci-dessous
//   // const expirationDateMin = new Date(expirationDateHours).setMinutes(0);
//   // const expirationDate = new Date(expirationDateMin).setSeconds(0);

//   return new Date(expirationDate);
// }

export const setDatesIntoLocalStorage = () => {
  const today = new Date();
  localStorage.setItem("pwaInstallDate", JSON.stringify(today));

  const expirationDate = new Date(getExpirationDate(today));
  localStorage.setItem("pwaExpirationDate", JSON.stringify(expirationDate));
};
