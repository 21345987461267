// npm imports
import React, { Component } from "react";
import withRouter from "../../hooks/withRouter";

// intern imports.
// components
import AppExpiration from "../AppExpiration";
import Footer from "../MixAndFooter";
import Header from "../MixAndHeader";
import Home from "../MixAndCastHome";
import ProductSelector from "../EquivalentProductSelector";
// import Simulator from "../MixAndFlowSimulator";
// import SimulatorResults from "../MixAndFlowSimulatorResults";
// assets
import Landscape from "../../assets/img/Mix&Cast_Landscape_siteweb.jpg";
// import Skatepark from "../../assets/img/MixAndFlow_Visuel_Skatepark.jpg";
// import Stairway from "../../assets/img/Mix&Cast_Landscape_siteweb.jpg";
import LogoOnTabletAndAbove from "../../assets/img/MixAndCAST_logo_blanc.png";
import LogoOnMobile from "../../assets/img/MixAndCAST_logo_blanc.png";
// utils

// local imports
import {
  cementStep,
  otherProducts,
  sikaProducts,
  sikaProductNames,
} from "../../data/mixAndCastData/selectData";
import {
  cementPortionText,
  otherProductSelectorText,
  productSelectorText,
  sikaProductSelectorText,
} from "../../data/mixAndCastData/textData";

class Calculator extends Component {
  state = {
    currentScreen: "home",
    // userChoices: [],
  };

  changeCurrentScreen = (event) => {
    this.setState({
      currentScreen: event.currentTarget.dataset.screen,
    });
  };

  changeCurrentState = (
    currentScreen
    //  userChoices
  ) => {
    this.setState({
      currentScreen,
      // userChoices,
    });
  };

  render() {
    const {
      currentScreen,
      // userChoices
    } = this.state;
    const { unauthorizeCurrentUser } = this.props;

    // default
    const formBackgroundImage = Landscape;

    const data =
      currentScreen === "sika-products" ? sikaProducts : otherProducts;
    const texts =
      currentScreen === "sika-products"
        ? sikaProductSelectorText
        : otherProductSelectorText;

    return (
      <div className="app mix-and-cast">
        <AppExpiration unauthorizeCurrentUser={unauthorizeCurrentUser} />

        <Header
          handleNav={this.changeCurrentState}
          currentScreen={currentScreen}
          LogoOnTabletAndAbove={LogoOnTabletAndAbove}
          LogoOnMobile={LogoOnMobile}
        />

        <main
          className="main"
          style={{ backgroundImage: `url(${formBackgroundImage})` }}
        >
          {/* <div>user agent: {window.navigator.userAgent}</div> */}

          {/* SCREEN SELECTOR */}
          {currentScreen === "home" && (
            <Home
              handleCtaClick={this.changeCurrentScreen}
              backgroundImage={Landscape}
            />
          )}

          {(currentScreen === "sika-products" ||
            currentScreen === "other-products") && (
            <ProductSelector
              cementPortionText={cementPortionText}
              cementStep={cementStep}
              currentScreen={currentScreen}
              data={data}
              productNames={sikaProductNames}
              productSelectorText={productSelectorText}
              texts={texts}
            />
          )}

          {/* {currentScreen === "simulator" && (
            <Simulator
              handleResultValidation={this.changeCurrentState}
              userChoices={userChoices}
            />
          )}

          {currentScreen === "simulator-results" && (
            <SimulatorResults
              changeCurrentScreen={this.changeCurrentScreen}
              userChoices={userChoices}
            />
          )} */}
        </main>

        <Footer changeCurrentScreen={this.changeCurrentScreen} />
      </div>
    );
  }
}

export default withRouter(Calculator);
