import React from "react";

const CarbonGainBlock = ({ result, title }) => {
  return (
    <div className="carbon-gain-block">
      <h3>{title}</h3>

      <p>{Math.abs(Math.round(result))} %</p>
    </div>
  );
};

export default CarbonGainBlock;
