// npm imports
import React from "react";

// internal imports
import Logo from "../../assets/img/logo-192.png";
import { firstVisitModalText } from "../../data/firstVisitModal";

function FirstVisitModal({ backgroundImage, closeModal }) {
  function handleClick() {
    // setDatesIntoLocalStorage();

    closeModal();
  }

  return (
    <div id="modal" className="modal">
      <div
        className="modal__content"
        style={
          backgroundImage
            ? { backgroundImage: `url(${backgroundImage})` }
            : undefined
        }
      >
        <div className="modal__content--specifics">
          <img
            className="modal__logo"
            src={Logo}
            alt="logo mix and flow"
            width={120}
            height={120}
          />

          <p>{firstVisitModalText.content}</p>

          <button
            className="btn btn-lg btn-primary modal__close"
            onClick={handleClick}
          >
            {firstVisitModalText.cta}
          </button>
        </div>
      </div>
    </div>
  );
}

export default FirstVisitModal;
