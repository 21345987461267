// npm imports
import React from "react";

// intern imports.
import SikaLogoOnTableAndAbove from "../../assets/img/Sika_ClaimU_pos_rgb.svg";
import SikaLogoOnMobile from "../../assets/img/Sika_NoClaim_pos_rgb_mobile.png";
import Header from "../Header";

// local imports

export default function SikaHeader({ withBurger }) {
  return (
    <Header withBurger={withBurger} className="header header--carbon-footprint">
      <h1>
        <img
          className="header__brand-logo display-on-mobile"
          src={SikaLogoOnMobile}
          alt="logo-sika"
        />

        <img
          className="header__brand-logo display-on-tablet-and-above"
          src={SikaLogoOnTableAndAbove}
          alt="logo-sika"
        />
      </h1>
    </Header>
  );
}
