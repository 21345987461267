// npm imports
import React, { useEffect, useRef, useState } from "react";

// internal imports
import AppleInstallSteps from "../../assets/img/iphone-addtohomescreen.png";
import Spinner from "../../atoms/spinner";
import { installModalText } from "../../data/mixAndFlowData/textData";
import { getExpirationDate } from "../../utils/dateUtils";
import SikaHeader from "../SikaHeader";

const State = {
  InitialState: "initial", // le bouton est affiché mais l'installation n'est pas possible -> IsNotInstallableBeforeInteraction

  IsInstallable: "isInstallableBeforeInteraction", // est installable mais ne s'installe pas car pas d'action de l'utilisateur -> bouton affiché
  InstallationInProgress: "installationInProgress",
  SuccessfulInstall: "successfulInstall", // l'installation a été accepté par l'utilisateur -> merci d'avoir installé l'applicaiton

  IsApple: "isApple",
};

function InstallModalContent({ onClick, state }) {
  if (state === State.IsApple) {
    return (
      <>
        <div
          className="specifics__text"
          dangerouslySetInnerHTML={{
            __html: installModalText.appleInstallText,
          }}
        />

        <img
          className="specifics__image"
          src={AppleInstallSteps}
          alt="étapes installation Apple"
        />

        <button className="btn" onClick={onClick}>
          {installModalText.appleCloseButton}
        </button>
      </>
    );
  }

  if (state === State.SuccessfulInstall) {
    return (
      <span className="specifics__text">
        Merci d'avoir installé l'application
      </span>
    );
  }

  if (state === State.InstallationInProgress) {
    return <Spinner />;
  }

  if (state === State.InitialState || state === State.IsInstallable) {
    return (
      <button id="modal__install-button" className="btn" onClick={onClick}>
        {installModalText.installButton}
      </button>
    );
  }

  return <span>Une erreur a eu lieu. Veuillez réessayer plus tard.</span>;
}

function InstallModal({
  backgroundImage,
  closeModal,
  updateLocalstorage,
  visible,
  header,
}) {
  const deferredPrompt = useRef();
  const [currentState, setCurrentState] = useState(State.InitialState);

  useEffect(() => {
    let isApple =
      window.navigator.userAgent.includes("iPhone") ||
      window.navigator.userAgent.includes("iPad") ||
      window.navigator.userAgent.includes("Mac");

    if (isApple && currentState === State.InitialState) {
      displayApplePrompt();
    } else {
      window.addEventListener("beforeinstallprompt", onIsInstallable);

      return () =>
        window.removeEventListener("beforeinstallprompt", onIsInstallable);
    }
  });

  // APPLE
  const displayApplePrompt = () => {
    setCurrentState(State.IsApple);
  };

  // DESKTOP AND ANDROID
  const onIsInstallable = (e) => {
    // Prevent Chrome 67 and earlier from automatically showing the prompt
    e.preventDefault();
    // Stash the event so it can be triggered later.
    deferredPrompt.current = e;

    setCurrentState(State.IsInstallable);
  };

  const onUserClick = () => {
    if (currentState === State.IsInstallable) {
      installPwa();
    }
  };

  const installPwa = () => {
    setCurrentState(State.InstallationInProgress);

    // Show the prompt
    deferredPrompt.current.prompt();

    // Wait for the user to respond to the prompt
    deferredPrompt.current.userChoice.then(function (choiceResult) {
      if (choiceResult.outcome === "accepted") {
        // ajouter dans le localstorage les pwaInstallDate et pwaExpirationDate
        setDatesIntoLocalStorage();
      } else {
        setCurrentState(State.IsInstallable);
      }
    });
  };

  // COMMON FUNCTIONS
  const setDatesIntoLocalStorage = () => {
    const today = new Date();
    localStorage.setItem("pwaInstallDate", JSON.stringify(today));

    const expirationDate = new Date(getExpirationDate(today));
    localStorage.setItem("pwaExpirationDate", JSON.stringify(expirationDate));

    updateLocalstorage();
    setCurrentState(State.SuccessfulInstall);
  };

  // si la modal ne doit pas être visible ou qu'on est à l'état initial (donc quand on n'a pas entendu l'event pour ce qui est de android et qu'on n'a pas detecté qu'on est sur un apple le cas échéant)
  if (!visible || currentState === State.InitialState) {
    return null;
  }

  return (
    <div id="modal" className={`modal${header ? "__with-header" : ""}`}>
      <SikaHeader />
      
      <div className="modal__content">
        <div className="modal__content--specifics">
          <InstallModalContent
            state={currentState}
            onClick={
              currentState === State.IsApple
                ? setDatesIntoLocalStorage
                : onUserClick
            }
          />

          {currentState === State.SuccessfulInstall && (
            <button className="btn modal__close" onClick={closeModal}>
              Fermez
            </button>
          )}
        </div>
      </div>
    </div>
  );
}

export default InstallModal;
