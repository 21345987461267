export const launcherText = {
  title: "Applications pôle béton",
  description: "",
  ctaButtons: [
    {
      text: "Mix and Flow",
      link: "/mix-and-flow",
    },
    {
      text: "Évaluation carbone",
      link: "/bilan-carbon",
    },
    {
      text: "Mix and Cast",
      link: "/mix-and-cast",
    },
  ],
};