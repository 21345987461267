// npm imports
import React, { Component } from "react";
import { Button } from "react-bootstrap";

// intern imports.
import { homeText } from "../../data/mixAndFlowData/homeText";

// local imports
class MixAndFlowHome extends Component {
  render() {
    const { handleCtaClick } = this.props;

    return (
      <section className="home">
        <h2 className="title">{homeText.title}</h2>
        {homeText.description && <p>{homeText.description}</p>}

        <div className="home__cta">
          {homeText.ctaButtons.map((button) => (
            <Button
              key={button.text}
              size="lg"
              className="home__cta__item"
              data-screen={button.screen}
              onClick={handleCtaClick}
              dangerouslySetInnerHTML={{
                __html: button.text,
              }}
            />
          ))}
        </div>
      </section>
    );
  }
}

export default MixAndFlowHome;
